import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { DateWithEventColorType, SecondEventsType } from "./CalendarNew"
import { Box, styled, TableCell } from "@mui/material"

const DayTableCell = styled(TableCell)({
	// borderBottom: "2px solid",
	// border: "2px solid white",
	padding: "3px",
	cursor: "pointer",
})
const ColorBlock = styled(Box)({
	display: "flex",
	flexDirection: "column",
	flexWrap: "nowrap",
	alignItems: "stretch",
	justifyContent: "flex-end",
	minHeight: "2rem",
	//  width: 100%,
})
const DotColorsBlock = styled(Box)({
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
})
const Dot = styled(Box)({
	height: "5px",
	width: "5px",
	margin: "1px",
	borderRadius: "50%",
})
const LineWrk = styled(Box)({
	minHeight: "3px",
	minWidth: "95%",
	padding: "1px",
})
interface DayProps {
	date: Date
	selectedDate: Date
	setSelectedDate: Dispatch<SetStateAction<Date>>
	eventsInMonth: Array<DateWithEventColorType>
	weekDay: number
	secondEventsArray?: SecondEventsType[]
}

const Day: React.FC<DayProps> = ({
	date,
	setSelectedDate,
	selectedDate,
	eventsInMonth,
	weekDay,
	secondEventsArray,
}) => {
	const currentDate = new Date().toLocaleDateString()
	const [colors, setColors] = useState<Array<string>>([])
	const [dotColors, setDotColors] = useState<string[]>([])
	//   const [sqrColors, setSqrColors] = useState<string[]>([]);

	useEffect(() => {
		if (
			secondEventsArray &&
			date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
			// || date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
		) {
			setDotColors(
				secondEventsArray
					.filter(
						(ev) =>
							ev.days.split(",").includes(weekDay.toString()) ||
							(ev.date &&
								new Date(ev.date).setHours(0, 0, 0, 0) ===
									date.setHours(0, 0, 0, 0))
					)
					.map((foo) => foo.color)
			)
		} else {
			setDotColors([])
		}
	}, [weekDay, date, secondEventsArray])

	useEffect(() => {
		setColors(
			eventsInMonth
				.filter(
					(ev) => ev.date.toLocaleDateString() === date.toLocaleDateString()
				)
				.map((elem) => elem.colors)
				.toString()
				.split(",")
		)

		/*        if(
                    (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                        || date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0))
                ){
                    setColors(eventsInMonth
                        .filter(ev=>ev.date.toLocaleDateString()===date.toLocaleDateString())
                        .map(elem=>elem.colors).toString().split(','))
                }else{
                    setColors([])
                }*/
	}, [eventsInMonth, date])

	return (
		<DayTableCell
			onClick={() => setSelectedDate(date)}
			//   className={
			//     date.toLocaleDateString() === currentDate
			//       ? s.dayElem + " " + s.dayElemCurrent
			//       : s.dayElem
			//   }

			sx={
				date === selectedDate
					? { borderColor: "#27AC69" }
					: date.toLocaleDateString() === currentDate
					? { borderColor: "#ac2777" }
					: {}
			}
		>
			{date.getDate()}
			<ColorBlock>
				<DotColorsBlock>
					{dotColors.map((dc, index) => {
						return <Dot key={index} sx={{ backgroundColor: dc }} />
					})}
				</DotColorsBlock>
				{colors.map((color, index) => {
					return <LineWrk key={index} sx={{ backgroundColor: color }} />
				})}
			</ColorBlock>
		</DayTableCell>
	)
}

export default Day
