import {
	Box,
	Button,
	Divider,
	IconButton,
	Typography,
	useMediaQuery,
	// useTheme,
} from "@mui/material"
import React, { FC, useState, useEffect } from "react"
import {
	AnswerType,
	QuestionsType,
	StructureUseAnswer,
} from "../../../TypesComp/TypesComp"
import AnswerPresentElem from "../TestInterComp/presentElems/AnswerPresentElem"
import { sortAnswersById } from "../../../BLL/sorts"
import {
	FlexBetween,
	ListBlock,
	ListBlockFlex,
} from "../../../theme/styledComponents"
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined"
// import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined"
import {
	CONFIRM_TITLE_T,
	MISS_ANSWER_T,
	NO_ANSWER_T,
	TRUE_ANSWER_T,
} from "../../../CONSTANTS/constsTrans"
import { NON_MOBILE } from "../../../CONSTANTS/constsSetting"
import ModalShowingImage from "./ModalShowingImage"

interface QuestionAndAnswerscontentProps {
	currentQuestion: QuestionsType | undefined
	answers: AnswerType[]
	// handleAnswer(an: AnswerType): void
	structureUseAnswer: StructureUseAnswer | undefined
	checkSpell(an: AnswerType): void
}
const QuestionAndAnswerscontent: FC<QuestionAndAnswerscontentProps> = ({
	currentQuestion,
	answers,
	structureUseAnswer,
	checkSpell,
}) => {
	// const theme = useTheme()
	const widthImg = (window.innerWidth / 100) * 80
	const heightImg = (window.innerHeight / 100) * 30
	const isNonMobile = useMediaQuery(NON_MOBILE)
	const [currentAnswer, setCurrentAnswer] = useState<AnswerType | undefined>(
		undefined
	)
	const [trueAnswer, setTrueAnswer] = useState<AnswerType | undefined>(
		undefined
	)
	const [message, setMessage] = useState<string>()
	const [isShowImgInModal, setIsShowImgInModal] = useState<boolean>(false)

	useEffect(() => {
		setTrueAnswer(answers.filter((elem) => elem.is_true)[0])
		setMessage(undefined)
	}, [answers])

	const confirmAnswer = () => {
		if (currentAnswer?.id === trueAnswer?.id) {
			setMessage(TRUE_ANSWER_T)
		} else {
			setMessage(trueAnswer?.name)
		}

		if (!currentAnswer) {
			setMessage(NO_ANSWER_T)
		}
		!!currentAnswer && checkSpell(currentAnswer)
	}

	const selectAnswer = (ans: AnswerType) => {
		setMessage(undefined)
		setCurrentAnswer(ans)
	}

	const showImage = () => {
		setIsShowImgInModal(true)
	}
	const closeImage = () => {
		setIsShowImgInModal(false)
	}
	return (
		<ListBlockFlex sx={{ height: window.innerHeight - 110 }}>
			<FlexBetween>
				<Typography paragraph>{currentQuestion?.name}</Typography>
				{!isNonMobile && !!currentQuestion?.image && (
					<IconButton aria-label="delete" onClick={showImage}>
						<ImageOutlinedIcon />
					</IconButton>
				)}
			</FlexBetween>

			{isNonMobile && (
				<>
					{!!currentQuestion?.image && (
						<Box
							sx={{
								height: heightImg,
								maxWidth: widthImg,
								overflow: "auto",
								cursor: "pointer",
							}}
							onClick={showImage}
						>
							<img
								src={`${currentQuestion?.image}?w=${heightImg}&h=${widthImg}&fit=crop&auto=format`}
								alt={"no img"}
								loading="lazy"
								height={heightImg - 100}
								// width={widthImg > heightImg ? "" : widthImg}
							/>
						</Box>
					)}
				</>
			)}

			<ListBlock
				mt={2}
				direction={"column"}
				divider={<Divider orientation="vertical" flexItem />}
				spacing={1}
			>
				{sortAnswersById(answers).map((an) => {
					return (
						<AnswerPresentElem
							key={an.id}
							isTest={true}
							answer={an}
							ondblclick={() => {}}
							selectAnswer={selectAnswer}
							is_current={an === currentAnswer}
						/>
					)
				})}
			</ListBlock>

			{/* <IconButton size="large" onClick={checkSpell}>
				<SpellcheckIcon color="success" />
			</IconButton> */}
			{trueAnswer?.id !== currentAnswer?.id && !!message && (
				<Typography color={"text.info"}>{MISS_ANSWER_T}</Typography>
			)}

			<Typography
				paragraph
				color={trueAnswer?.id === currentAnswer?.id ? "text.success" : "error"}
			>
				{message}
			</Typography>
			<Button
				variant="contained"
				onClick={confirmAnswer}
				size="large"
				disabled={structureUseAnswer?.checked}
			>
				{CONFIRM_TITLE_T}
			</Button>

			{isShowImgInModal && currentQuestion?.image && (
				<ModalShowingImage
					open={isShowImgInModal && !!currentQuestion?.image}
					image={currentQuestion?.image}
					closeImage={closeImage}
				/>
			)}
		</ListBlockFlex>
	)
}

export default QuestionAndAnswerscontent
