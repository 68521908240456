import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Day from "./Day";
import s from './CalendarNew.module.css'
import {DateWithEventColorType, SecondEventsType} from "./CalendarNew";

interface WeekProps {
    selectedDate: Date
    setSelectedDate: Dispatch<SetStateAction<Date>>
    numberWeek: number
    firstDay: number
    selectedYear: number
    selectedMonth: number
    eventsInMonth:Array<DateWithEventColorType>
    secondEventsArray:SecondEventsType[]
}

const Week: React.FC<WeekProps> = (
    {numberWeek, selectedDate, firstDay, selectedYear, selectedMonth,
        setSelectedDate, eventsInMonth, secondEventsArray}) => {
    const [daysList, setDaysList] = useState<Array<Date>>([])
    const [firstDate, setFirstDate] = useState<string>(new Date(selectedYear, selectedMonth,1+(7*numberWeek)-firstDay).toString())


    useEffect(()=>{
        setFirstDate(new Date(selectedYear, selectedMonth,1+(7*numberWeek)-firstDay).toString())
    },[selectedYear, selectedMonth, firstDay,numberWeek])

    useEffect(() => {
        let listDaysDate = [];
        for (let day = 0; day < 7; day++) {
            listDaysDate.push(new Date(new Date(firstDate).setDate(new Date(firstDate).getDate() + day)))
        }
        setDaysList(listDaysDate)
    }, [firstDate])

    return (
        <>
            {daysList.map((day, index)=>{
                return(
                        <Day key={index}
                             weekDay={day.getDay()}
                             date={day}
                             setSelectedDate={setSelectedDate}
                             selectedDate={selectedDate}
                             secondEventsArray={secondEventsArray}
                             eventsInMonth={eventsInMonth}/>
                )
            })}
        </>
    );
};

export default Week;