import {
	AnswerType,
	ChapterType,
	QuestionsType,
	SubjectType,
} from "../TypesComp/TypesComp"

export const sortSubjectById = (sub: SubjectType[]): SubjectType[] => {
	return sub.sort((a, b) => {
		if (a.id > b.id) {
			return 1
		} else if (a.id < b.id) {
			return -1
		} else return 0
	})
}

export const sortChaptersById = (elem: ChapterType[]): ChapterType[] => {
	return elem.sort((a, b) => {
		if (a.id! > b.id!) {
			return 1
		} else if (a.id! < b.id!) {
			return -1
		} else return 0
	})
}

export const sortQuestionsById = (elem: QuestionsType[]): QuestionsType[] => {
	return elem.sort((a, b) => {
		if (a.id! > b.id!) {
			return 1
		} else if (a.id! < b.id!) {
			return -1
		} else return 0
	})
}
export const sortQuestionsByName = (elem: QuestionsType[]): QuestionsType[] => {
	return elem.sort((a, b) => {
		if (a.name! > b.name!) {
			return 1
		} else if (a.name! < b.name!) {
			return -1
		} else return 0
	})
}

export const sortAnswersById = (elem: AnswerType[]): AnswerType[] => {
	return elem.sort((a, b) => {
		if (a.id! > b.id!) {
			return 1
		} else if (a.id! < b.id!) {
			return -1
		} else return 0
	})
}
