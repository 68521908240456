import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ModalInner } from '../../../../theme/styledComponents';
import { CANCEL_T, CONTINUM_TITLE_T, TITLE_IS_MISSING_ANSWER_T } from '../../../../CONSTANTS/constsTrans';

interface ConfirmFormProps {
    open: boolean,
    closeModal(): void,
    onConfirm(): void
}
const ConfirmForm: FC<ConfirmFormProps> = ({ open, closeModal, onConfirm }) => {
    return (
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="confirm-modal-form"
            aria-describedby="confirm-modal-description"
            container={() => document.getElementsByClassName("fullscreen")[0]}
        >
            <ModalInner
                sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
                <Paper sx={{padding:10, width:"400px"}}>
                    <Box sx={{width:"100%"}}>
                        <Typography>
                        {TITLE_IS_MISSING_ANSWER_T}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection:"row", width:"100%" }}>
                        <Button sx={{margin:1}} variant='outlined' color='success' onClick={onConfirm}>{CONTINUM_TITLE_T}</Button>
                        <Button sx={{margin:1}} variant='outlined' color='warning' onClick={closeModal}>{CANCEL_T}</Button>
                    </Box>
                    </Box>
                    
                </Paper>


            </ModalInner>
        </Modal>
    );
};

export default ConfirmForm;