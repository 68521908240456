import { Box, Divider, IconButton, Typography } from "@mui/material"
import React, { Dispatch, FC, SetStateAction, useContext, useState } from "react"
import { ANSWERS_T } from "../../../CONSTANTS/constsTrans"
import AddIcon from "@mui/icons-material/Add"
import { AnswerType, QuestionsType } from "../../../TypesComp/TypesComp"
import { ListBlock } from "../../../theme/styledComponents"
import { sortAnswersById } from "../../../BLL/sorts"
import AnswerPresentElem from "./presentElems/AnswerPresentElem"
import AnswerForm from "./forms/AnswerForm"
import { addAnswersToBase, putAnswerToBase } from "../../../API/RequestServices"
import FlexEvenly from "../../../components/flex-evenly"
import { MainContext } from "../../../App"

interface AnswersPageProps {
	questions: QuestionsType[]
	currentQuestion: QuestionsType | undefined
	answers: AnswerType[]
	setAnswers: Dispatch<SetStateAction<AnswerType[]>>
	currentAnswer: AnswerType | undefined
	// setCurrentAnswer: Dispatch<SetStateAction<AnswerType | undefined>>
	selectAnswer(answ: AnswerType | undefined): void
}
const AnswersPage: FC<AnswersPageProps> = ({
	currentQuestion,
	answers,
	// setCurrentAnswer,
	currentAnswer,
	setAnswers,
	selectAnswer,
}) => {
	const mainContext = useContext(MainContext)
	const [isOpenForm, setIsOpenForm] = useState<boolean>(false)

	// const selectAnswer = (answer: AnswerType) => {
	// 	setCurrentAnswer(answer)
	// }

	const openFormAnswer = () => {
		mainContext?.currentUser?.is_staff && setIsOpenForm(true)
	}

	const openFormAnswerForAdd = () => {
		if (currentQuestion && mainContext?.currentUser?.is_staff) {
			selectAnswer(undefined)
			setIsOpenForm(true)
		}
	}
	const addAnswer = (nAnswer: AnswerType) => {
		// console.log(nAnswer)
		const addAnswerToBase = async () => {
			const data = {
				// name: currentChapter? currentChapter.name: CHAPTER_NAME_T+(chapters.length+1).toString(),
				name: nAnswer.name,
				question: currentQuestion!.id!.toString(),
				// number: (answers.length + 1).toString(),
				is_active: nAnswer.is_active.toString(),
				is_true: nAnswer.is_true.toString(),
				// is_checked: nAnswer.is_checked.toString(),
			}
			if (!!nAnswer.id) {
				await putAnswerToBase(nAnswer.id, data).then((ans: AnswerType) => {
					if (typeof ans === "string") {
						// TODO обработка ошибки
					} else {
						setAnswers((prev) => [
							...prev.filter((elem) => elem.id !== ans.id),
							ans,
						])
						setIsOpenForm(false)
					}
				})
			} else {
				await addAnswersToBase(data).then((ans: AnswerType) => {
					if (typeof ans === "string") {
						// TODO обработка ошибки
					} else {
						setAnswers((prev) => [...prev, ans])
						setIsOpenForm(false)
					}
				})
			}
		}

		!!currentQuestion && addAnswerToBase()
	}
	return (
		<Box>
			<FlexEvenly>
				<Typography>{ANSWERS_T}</Typography>
				{mainContext?.currentUser?.is_staff &&
					<IconButton
						aria-label="add"
						color="success"
						onClick={openFormAnswerForAdd}
					>
						<AddIcon />
					</IconButton>
				}
			</FlexEvenly>

			<ListBlock
				direction={"column"}
				divider={<Divider orientation="vertical" flexItem />}
				spacing={1}
			>
				{sortAnswersById(answers).map((answer) => {
					return (
						<AnswerPresentElem
							key={answer.id}
							answer={answer}
							ondblclick={openFormAnswer}
							selectAnswer={selectAnswer}
							is_current={answer === currentAnswer}
						/>
					)
				})}
			</ListBlock>

			{isOpenForm && (
				<AnswerForm
					answer={currentAnswer}
					open={isOpenForm}
					addAnswer={addAnswer}
					setOpen={setIsOpenForm}
				/>
			)}
		</Box>
	)
}

export default AnswersPage
