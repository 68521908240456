import { styled, TextField } from "@mui/material"
// import React from "react"

const LoginInput = styled(TextField)(({ theme }) => ({
	boxShadow:
		theme.palette.mode === "dark"
			? "0 0 0 100px #2f4352 inset"
			: "0 0 0 100px #fff inset",
	".MuiOutlinedInput-input:-webkit-autofill": {
		WebkitBoxShadow:
			theme.palette.mode === "dark"
				? "0 0 0 100px #2f4352 inset"
				: "0 0 0 100px #fff inset",
	},
	"& label.Mui-focused": {
		color: theme.palette.text.primary,
	},
	// "& .MuiInput-underline:after": {
	// 	borderBottomColor: "green",
	// },
	"& .MuiOutlinedInput-root": {
		// "& fieldset": {
		// 	borderColor: "red",
		// },
		"&:hover fieldset": {
			borderColor: theme.palette.text,
		},
		"&.Mui-focused fieldset": {
			borderColor: theme.palette.text.primary,
		},
	},
}))

export default LoginInput
