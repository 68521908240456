import React from "react"
import {
	Box,
	List,
	ListItemButton,
	styled,
	Typography,
	useTheme,
} from "@mui/material"
import { tokens } from "../../theme/theme"

export const BoxNavBlock = (props: any) => {
	const theme = useTheme()
	const colors = tokens(theme.palette.mode)

	const BoxStyle = styled(Box)({
		width: "100%",
		// borderBottom: `1px solid ${colors.borderColor}`,
	})
	return <BoxStyle {...props} />
}

export const BoxBrand = (props: any) => {
	const BoxStyle = styled(Box)({
		display: "flex",
		alignItems: "center",
		gap: "10px",
		padding: "10px 15px",
		cursor: "pointer",
	})
	return <BoxStyle {...props} />
}

export const TypographyBrandTitle = (props: any) => {
	const theme = useTheme()
	const colors = tokens(theme.palette.mode)

	const TypographyStyle = styled(Typography)({
		// color: `${
		// 	theme.palette.mode === "dark"
		// 		? colors.white.DEFAULT
		// 		: colors.black.DEFAULT
		// }`,
	})
	return <TypographyStyle {...props} />
}

export const ListNav = (props: any) => {
	const ListStyle = styled(List)({
		marginBottom: "55px",
	})
	return <ListStyle {...props} />
}

export const ListItemButtonNavItem = (props: any) => {
	const theme = useTheme()
	const colors = tokens(theme.palette.mode)

	const ListItemButtonStyle = styled(ListItemButton)({
		"&:hover": {
			backgroundColor: "#414c49 !important",
			color: "#fff",
			borderRadius: "4px",
			// "& .MuiSvgIcon-root": {
			// 	color: `${colors.white.DEFAULT} !important`,
			// },
		},
	})
	return <ListItemButtonStyle {...props} />
}
