import React, { Dispatch, SetStateAction, useContext } from "react"
import {
	AppBar,
	Box,
	Grid,
	IconButton,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material"
import {
	LightMode,
	DarkMode,
	MenuOutlined,
	NotificationsNone,
	LoginOutlined,
} from "@mui/icons-material"
import { ColorModeContext } from "../../theme/theme"

import { MAIN_TITLE_T } from "../../CONSTANTS/constsTrans"
import { FullScreenHandle } from "react-full-screen"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import { FlexBetween } from "../../theme/styledComponents"

interface TopBarComponentProps {
	setIsOpen: Dispatch<SetStateAction<boolean>>
	isOpen: boolean
	isNonMobile: boolean
	isLogin: boolean
	setIsShowLoginForm: Dispatch<SetStateAction<boolean>>
	handleFullScreen: FullScreenHandle
}

const TopBarComponent: React.FC<TopBarComponentProps> = ({
	setIsOpen,
	isOpen,
	isNonMobile,
	isLogin,
	setIsShowLoginForm,
	handleFullScreen,
}) => {
	const theme = useTheme()
	const colorMode: any = useContext(ColorModeContext)

	const clickHandleFullScreen = (action: boolean) => {
		!!action ? handleFullScreen.enter() : handleFullScreen.exit()
	}

	const renderBoxRightBtnIcon = (
		<Box display="flex">
			<Grid>
				<IconButton onClick={colorMode.toggleColorMode}>
					{theme.palette.mode === "dark" ? <DarkMode /> : <LightMode />}
				</IconButton>
				{isLogin ? (
					<>
						<IconButton>
							<NotificationsNone />
						</IconButton>
						{!!handleFullScreen.active ? (
							<IconButton onClick={() => clickHandleFullScreen(false)}>
								<CloseFullscreenIcon />
							</IconButton>
						) : (
							<IconButton onClick={() => clickHandleFullScreen(true)}>
								<OpenInFullIcon />
							</IconButton>
						)}
					</>
				) : (
					<IconButton onClick={() => setIsShowLoginForm((prev) => !prev)}>
						<LoginOutlined />
					</IconButton>
				)}
			</Grid>
		</Box>
	)

	return (
		<AppBar position="static">
			<Toolbar
				sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
			>
				{isLogin ? (
					<FlexBetween>
						{!isOpen && (
							<MenuOutlined
								sx={{ mr: 1 }}
								onClick={() => setIsOpen((prev) => !prev)}
							/>
						)}

						<Typography>{MAIN_TITLE_T.toUpperCase()}</Typography>
					</FlexBetween>
				) : (
					<Box>
						<Typography>{MAIN_TITLE_T.toUpperCase()}</Typography>
					</Box>
				)}
				{isNonMobile && renderBoxRightBtnIcon}
				{!isNonMobile && !isOpen && renderBoxRightBtnIcon}
			</Toolbar>
		</AppBar>
	)
}

export default TopBarComponent
