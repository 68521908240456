import React, { FC } from "react"
import { UserProfileType } from "../../../TypesComp/TypesComp"
import { Box, Chip, Stack } from "@mui/material"

interface ProfilesListMobileProps {
	users: UserProfileType[]
	handleSelectUser(user: UserProfileType): void
	currentProfile: UserProfileType | undefined
    
}
const ProfilesListMobile: FC<ProfilesListMobileProps> = ({
	users,
	handleSelectUser,
	currentProfile,
}) => {
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-start",
					height: 60,
					overflowX: "scroll",
				}}
			>
				<Stack direction="row" spacing={{ xs: 1, sm: 2 }} useFlexGap>
					{users.map((user) => (
						<Chip
							key={user.id}
							onClick={() => handleSelectUser(user)}
							label={user.name}
							clickable
							variant={currentProfile?.id === user.id ? "filled" : "outlined"}
							sx={{
								"& .MuiChip-label": {
									display: "block",
									whiteSpace: "normal",
								},
							}}
							size={"medium"}
						/>
					))}
				</Stack>
			</Box>
            
		</Box>
	)
}

export default ProfilesListMobile
