import axios from "axios"
import { BASE_URL } from "../CONSTANTS/constsSetting"

// const [cookies, setCookie] = useCookies(["auth-token"]);
// export const base_url = "http://127.0.0.1:8025/"
const base_url = BASE_URL

const $authHost = axios.create({
	baseURL: base_url,
})

//.вставляю токен
const authInterceptor = (config: any) => {
	// console.log('authInterceptor', localStorage.getItem("Authorization"))
	// config.headers.Authorization = 'Token 5e1788700279ee385777cecf60dd2a23fff93ce7'
	config.headers.Authorization = JSON.parse(
		JSON.stringify(localStorage.getItem("Authorization"))
	)
	config.headers.accept = "application/json"
	return config
}

//_будет отработан перед каждым запросом и подставлять токен
$authHost.interceptors.request.use(authInterceptor)

export {
	// $host,
	$authHost,
}

export const defaultGet = (url: string, params?: any) => {
	return $authHost.get(url, params)
}

export const defaultPost = (url: string, data?: { [key: string]: any }) => {
	return $authHost.post(url, data)
}

export const defaultPut = (url: string, data: { [key: string]: any }) => {
	return $authHost.put(url, data)
}
export const defaultDelete = (url: string) => {
	return $authHost.delete(url)
}
