import {
	HomeOutlined,
	AutoGraphOutlined,
	MenuBookOutlined,
	SettingsOutlined,
} from "@mui/icons-material"
import {
	PART_ONE_TITLE_T,
	PART_TWO_TITLE_T,
	PART_THREE_TITLE_T,
	PART_FOUR_TITLE_T,
} from "../../CONSTANTS/constsTrans"

export const navMenu = [
	{
		name: PART_ONE_TITLE_T,
		icon: <HomeOutlined />,
		path: "/",
		id: 1,
	},
	{
		name: PART_TWO_TITLE_T,
		icon: <AutoGraphOutlined />,
		path: "/ptwop",
		id: 2,
	},
	{
		name: PART_THREE_TITLE_T,
		icon: <MenuBookOutlined />,
		path: "/pthrp",
		id: 3,
	},
	{
		name: PART_FOUR_TITLE_T,
		icon: <SettingsOutlined />,
		path: "/pfourp",
		id: 4,
	},
]
