import React from "react"
import TestsWrapper from "../TestsPart/TestsWrapper"

const PartTwoPage = () => {
	return (
		<TestsWrapper/>
	)
}

export default PartTwoPage
