import { Box, Typography } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { StatisticType, UserProfileType } from "../../../TypesComp/TypesComp"
import CalendarPage from "../../calendar/CalendarPage"
import DataChangerBlock from "./DataChangerBlock"
import { getStatisticFromBase } from "../../../API/RequestServices"

interface ProfileTestBlockMobileProps {
	currentProfile: UserProfileType | undefined
	isShowCal: boolean
}
const ProfileTestBlockMobile: FC<ProfileTestBlockMobileProps> = ({
	currentProfile,

	isShowCal,
}) => {
	const [currentDate, setCurrentDate] = useState<Date>(new Date())

    const [statistiks, setStatistiks] = useState<StatisticType[]>([])

            useEffect(() => {
                if (!!currentProfile) {
                    const _getStatisticFromBase = async () => {
                        await getStatisticFromBase(currentProfile.id).then(
                            (response: StatisticType[] | string) => {
                                if (typeof response !== "string") {
                                    setStatistiks([
                                        ...response.map((foo) => {
                                            return {
                                                ...foo,
                                                date: !!foo.date ? new Date(foo.date) : undefined,
                                            }
                                        }),
                                    ])
                                }
                            }
                        )
                    }
                    _getStatisticFromBase()
                }
            }, [currentProfile])
    const changeDate = (date:Date) => {
        setCurrentDate(date)
    }
	return (
		<>
			{isShowCal ? (
				<Box sx={{ maxWidth: 400 }}>
					<CalendarPage />
				</Box>
			) : (
				<DataChangerBlock currentDate={currentDate} changeDate={changeDate} />
			)}

			<Typography variant="h6" color={"text.success"}>
				{currentProfile?.name}
			</Typography>
			<Typography variant="h6" color={"text.info"}>
				{"Для мобильной версии страница находится в разработке"}
			</Typography>
		</>
	)
}

export default ProfileTestBlockMobile
