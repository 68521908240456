import { Box } from "@mui/material"
import React from "react"
import ChatWrapper from "../chat/ChatWrapper"

const PartThreePage = () => {
	return (
		<Box>
			<ChatWrapper />
		</Box>
	)
}

export default PartThreePage
