import React from "react"
import s from "./GenericModal.module.css"
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material"
import { NON_MOBILE } from "../../../../CONSTANTS/constsSetting"
import CloseIcon from "@mui/icons-material/Close"

interface GenericModalProps {
	children?: React.ReactNode

	closeModal(): void

	// onsubmit?: () => void

	// createMode?: boolean
	// photoMode?: boolean
	// validateImage?: (e: any) => void
	// isShowSubmitBtn?: boolean
	// isShowConfirmBtn?: boolean
}

const GenericModal: React.FC<GenericModalProps> = ({
	children,
	closeModal,
}) => {
	const theme = useTheme()
	const isNonMobile = useMediaQuery(NON_MOBILE)
	return (
		<Box
			className={s.modalWrapper}
			onClick={closeModal}
			// style={window.innerHeight<window.innerWidth?{position:"absolute"}:{position:'fixed'}}
			sx={{
				position: "fixed",
				// backgroundColor: theme.palette.background.default,
				height: window.innerHeight,
				padding: "10px",
			}}
		>
			<Box
				className={s.modalInner}
				sx={{
					// position: "fixed",
					backgroundColor: theme.palette.background.default,
				}}
				// style={{ width: getModalWidth() }}
				onClick={(e) => e.stopPropagation()}
			>
				<Box
					sx={
						!isNonMobile
							? {
									maxHeight: window.innerHeight - 50,
									overflowY: "auto",
									paddingBottom: "5px",
							  }
							: {
									maxHeight: window.innerHeight - 5,
									overflowY: "auto",
									paddingBottom: "5px",
							  }
					}
				>
					{children}
				</Box>
			</Box>
			<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
				{!isNonMobile && (
					<IconButton size="large">
						<CloseIcon color="error" />
					</IconButton>
				)}
			</Box>
		</Box>
	)
}

export default GenericModal
