import {
	ChevronLeftOutlined,
	LogoutOutlined,
	LoginOutlined,
} from "@mui/icons-material"
import {
	Box,
	Drawer,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	useTheme,
} from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import LogoWhite from "../../assets/images/sidebar/manWhite.png"
import LogoGray from "../../assets/images/sidebar/manGray.png"
import { navMenu } from "./navMenu"
import { useNavigate } from "react-router-dom"
import {
	BoxBrand,
	BoxNavBlock,
	ListItemButtonNavItem,
	ListNav,
	// TypographyBrandTitle,
} from "./stylesComp"
import { getTrans } from "../../utils/translate"
import { LOGIN_TITLE, LOGOUT_TITLE } from "../../CONSTANTS/consts"
// import AdminToolsWrapper from "../../pages/adminsTools/AdminToolsWrapper"
import { ADMINS_PART_T } from "../../CONSTANTS/constsTrans"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import { FlexBetween } from "../../theme/styledComponents"
import { UserProfileType } from "../../TypesComp/TypesComp"

interface SidebarComponentProps {
	setIsOpen: Dispatch<SetStateAction<boolean>>
	isOpen: boolean
	isNonMobile: boolean
	drawerWidth: string
	isLogin: boolean
	logOut(): void
	currentUser: UserProfileType | undefined
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({
	isOpen,
	setIsOpen,
	isNonMobile,
	drawerWidth,
	isLogin,
	logOut,
	currentUser,
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const goToPart = (path: string) => {
		navigate(`${path}`)
		!isNonMobile && setIsOpen(false)
	}
	const renderNavMenu = navMenu.map((element): JSX.Element => {
		return (
			<ListItem key={element.id} disablePadding>
				<ListItemButtonNavItem onClick={() => goToPart(element.path)}>
					<ListItemIcon>{element.icon}</ListItemIcon>
					<ListItemText
						primary={element.name}
						// primaryTypographyProps={{
						// 	fontWeight: "medium",
						// 	variant: "h3",
						// }}
					/>
				</ListItemButtonNavItem>
			</ListItem>
		)
	})
	const logout = () => {
		logOut()
	}
	const login = () => {
		console.log("login")
	}
	return (
		<Box component="nav">
			{isOpen && (
				<Drawer
					open={isOpen}
					onClose={() => setIsOpen(false)}
					variant="persistent"
					anchor="left"
					sx={{
						width: drawerWidth,
						"& .MuiDrawer-paper": {
							color: theme.palette.secondary.main,
							backgroundColor: theme.palette.primary.dark,
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					<BoxNavBlock>
						<Box>
							<FlexBetween>
								<BoxBrand>
									{theme.palette.mode === "dark" ? (
										<img src={LogoWhite} alt="man svg" height={40} />
									) : (
										<img src={LogoGray} alt="man svg" height={40} />
									)}
								</BoxBrand>
								<IconButton onClick={() => setIsOpen(!isOpen)}>
									<ChevronLeftOutlined />
								</IconButton>
							</FlexBetween>
						</Box>
						<ListNav>{renderNavMenu}</ListNav>
						{currentUser?.is_staff && (
							<ListNav>
								<ListItem disablePadding>
									<ListItemButtonNavItem onClick={() => goToPart("/admp")}>
										<ListItemIcon>
											<AdminPanelSettingsIcon />
										</ListItemIcon>
										<ListItemText primary={ADMINS_PART_T} />
									</ListItemButtonNavItem>
								</ListItem>
							</ListNav>
						)}
						<ListNav>
							<ListItem>
								<ListItemButtonNavItem onClick={isLogin ? logout : login}>
									<ListItemIcon>
										{isLogin ? <LogoutOutlined /> : <LoginOutlined />}
									</ListItemIcon>
									<ListItemText
										primary={
											isLogin
												? getTrans(`ln.${LOGOUT_TITLE}`)
												: getTrans(`ln.${LOGIN_TITLE}`)
										}
									/>
								</ListItemButtonNavItem>
							</ListItem>
						</ListNav>
					</BoxNavBlock>
				</Drawer>
			)}
		</Box>
	)
}

export default SidebarComponent
