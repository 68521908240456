import { Box, Typography } from '@mui/material';
import React from 'react';

const PartFourPage = () => {
    return (
			<Box>
				<Typography variant="body1" paragraph={true}>
					Part Four Page
				</Typography>
			</Box>
		)
};

export default PartFourPage;