import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import Week from "./Week"
import { DateWithEventColorType, SecondEventsType } from "./CalendarNew"
import {
	// Box,
	// Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"

interface MonthProps {
	selectedDate: Date
	setSelectedDate: Dispatch<SetStateAction<Date>>
	selectedYear: number
	selectedMonth: number
	eventsInMonth: Array<DateWithEventColorType>
	secondEventsArray: SecondEventsType[]
	languageDesignation: string
}
const Month: React.FC<MonthProps> = ({
	selectedDate,
	setSelectedDate,
	selectedYear,
	selectedMonth,
	eventsInMonth,
	secondEventsArray,
	languageDesignation,
}) => {
	const [daysName, setDaysName] = useState<Array<string>>([])
	// Кол-во дней в месяце
	const [totalDays, setTotalDays] = useState<number>(
		new Date(selectedYear, selectedMonth + 1, 0).getDate()
	)
	// День недели для первого дня месяца для расчета сдвига
	const [firstDay, setFirstDay] = useState<number>(
		new Date(new Date(selectedYear, selectedMonth, 1)).getUTCDay()
	)
	const [arrayWeeks, setArrayWeeks] = useState<Array<number>>([])

	useEffect(() => {
		let dayNames: string[] = []
		for (let i = 1; i < 8; i++) {
			dayNames.push(
				new Date(2022, 7, i).toLocaleString(languageDesignation, {
					weekday: "short",
				})
			)
		}
		setDaysName(dayNames)
	}, [languageDesignation])

	useEffect(() => {
		setTotalDays(new Date(selectedYear, selectedMonth + 1, 0).getDate())
		setFirstDay(new Date(new Date(selectedYear, selectedMonth, 1)).getUTCDay())
	}, [selectedMonth, selectedYear])

	useEffect(() => {
		let listWeeksNumbers = []
		for (
			let i = 0;
			i < new Array(Math.ceil((firstDay + totalDays) / 7)).length;
			i++
		) {
			listWeeksNumbers.push(i)
		}
		setArrayWeeks(listWeeksNumbers)
	}, [firstDay, totalDays])

	return (
		<TableContainer component={Paper}>
			<Table aria-label="date table">
				<TableHead>
					<TableRow>
						{daysName.map((name) => {
							return (
								<TableCell
									key={name}
									align="center"
									component={"th"}
									sx={{ padding: "3px 0" }}
								>
									{name}
								</TableCell>
							)
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{arrayWeeks.map((elem, index) => {
						return (
							<TableRow
								key={index}
								// sx={{ minHeight: "250px" }}
								//   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<Week
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									numberWeek={elem}
									firstDay={firstDay}
									selectedYear={selectedYear}
									selectedMonth={selectedMonth}
									eventsInMonth={eventsInMonth}
									secondEventsArray={secondEventsArray}
								/>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>

			{/* <div className={s.weeksListBlock}>
				{arrayWeeks.map((elem, index) => {
					return (
						<Week
							key={index}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							numberWeek={elem}
							firstDay={firstDay}
							selectedYear={selectedYear}
							selectedMonth={selectedMonth}
							eventsInMonth={eventsInMonth}
							secondEventsArray={secondEventsArray}
						/>
					)
				})}
			</div> */}
		</TableContainer>
	)
}

export default Month
