import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import CalendarPage from "../calendar/CalendarPage"
import { LOCALE_DATE_PARAM_STRING_RU, NON_MOBILE } from "../../CONSTANTS/constsSetting"
import { getStatisticFromBase } from "../../API/RequestServices"
import { StatisticType } from "../../TypesComp/TypesComp"
import { MainContext } from "../../App"
import StatisticElemBlock from "../profiles/presentElems/StatisticElemBlock"
import { TITLE_SHOW_STATISTIC_T } from "../../CONSTANTS/constsTrans"

const HomePage = () => {
	const mainContext = useContext(MainContext)
	const isNonMobile = useMediaQuery(NON_MOBILE)
	// const [statistiks, setStatistiks] = useState<StatisticType[]>([])
	const [filteredStatistic, setFilteredStatistic] = useState<StatisticType[]>(
		[]
	)

	// useEffect(()=>{
	// 	if(mainContext?.setEvents){
	
	// 	}
	// },[statistiks, mainContext?.setEvents])

	useEffect(() => {
		if (mainContext?.selectedDate) {
			setFilteredStatistic([
				...mainContext?.statistics?.filter(
					(foo) =>
						foo.date?.toLocaleDateString([LOCALE_DATE_PARAM_STRING_RU]) ===
						mainContext.selectedDate?.toLocaleDateString([
							LOCALE_DATE_PARAM_STRING_RU,
						])
				),
			])
		} else {
			setFilteredStatistic([])
		}
	}, [mainContext?.selectedDate, mainContext?.statistics])

	const showUserStatistic = () => {
		if (!!mainContext?.currentUser?.id) {
			const _getStatisticFromBase = async () => {
				await getStatisticFromBase(mainContext!.currentUser!.id).then(
					(response: StatisticType[] | string) => {
						if (typeof response !== "string") {
							mainContext?.setStatistic([
								...response.map((foo) => {
									return {
										...foo,
										date: !!foo.date ? new Date(foo.date) : undefined,
									}
								}),
							])
						}
					}
				)
			}
			_getStatisticFromBase()
		}
	}

	return (
		<Box sx={isNonMobile ? { padding: 3 } : { padding: 1 }}>
			{/* <Typography variant="body1" paragraph={true}>
				Доброго времени суток, друзья! Home Page
			</Typography> */}
			<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Box
						sx={
							isNonMobile
								? { width: 400, height: 400 }
								: { width: "100%", height: 400 }
						}
					>
						<CalendarPage />
					</Box>
					<Box >
						{mainContext?.currentUser &&
							<Button variant="contained" onClick={showUserStatistic}>{TITLE_SHOW_STATISTIC_T}</Button>
						}
					</Box>

				</Box>
				{isNonMobile && mainContext?.statistics ?
					<Box sx={{maxWidth:"400px"}}>
						<StatisticElemBlock
							filteredStatistic={mainContext?.statistics}
						/>
					</Box> 
					:
					<Typography variant="body1" paragraph={true}>
						В мобильной версии отображается статистика только на выбранную дату.
					</Typography> 
				}

				<Box  sx={{maxWidth:"400px"}}>
					<StatisticElemBlock
						filteredStatistic={filteredStatistic}
					/>
				</Box>

			</Box>



		</Box>
	)
}

export default HomePage
