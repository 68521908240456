import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import {
	INTER_DATA_TITLE_T,
	WALKTHROUGH_TITLE_T,
} from "../../../CONSTANTS/constsTrans"

const FormControlStyled = styled(FormControl)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
})

interface SwitchBlockProps{
  testMode: string,
  setTestMode: Dispatch<SetStateAction<string>>
}
const SwitchBlock:FC<SwitchBlockProps> = ({testMode,setTestMode}) => {
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestMode((event.target as HTMLInputElement).value);
  };
  return (
    <FormControlStyled >
      {/* <FormLabel id="radio-buttons-group-label">Gender</FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="radio-buttons-group-label"
        defaultValue="inter"
        name="radio-buttons-group"
        value={testMode}
        onChange={handleChange}
      >
        <FormControlLabel value="inter" control={<Radio />} label={INTER_DATA_TITLE_T} />
        <FormControlLabel value="walkthrought" control={<Radio />} label={WALKTHROUGH_TITLE_T} />

      </RadioGroup>
    </FormControlStyled>
  );
};

export default SwitchBlock;