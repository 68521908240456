import React, { FC, useState } from "react"
import { UserProfileType } from "../../TypesComp/TypesComp"
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Modal,
	useMediaQuery,
	useTheme,
} from "@mui/material"
import { NON_MOBILE } from "../../CONSTANTS/constsSetting"
import TextInput from "../../components/inputs/TextInput"
import {
	SUBMIT_T,
	USERNAME_TITLE_T,
	USER_GPT_LABEL_T,
} from "../../CONSTANTS/constsTrans"
import { pink } from "@mui/material/colors"

interface ModalUpdateFormUserProps {
	open: boolean
	closeModal(): void
	currentProfile: UserProfileType
	updateUser(user: UserProfileType): void
}
const ModalUpdateFormUser: FC<ModalUpdateFormUserProps> = ({
	open,
	closeModal,
	currentProfile,
	updateUser,
}) => {
	const theme = useTheme()
	const isNonMobile = useMediaQuery(NON_MOBILE)
	const [updProf, setUpdProf] = useState({ ...currentProfile })

	const setStateUserAddFormValue = (val: Partial<UserProfileType>) => {
		updProf && setUpdProf({ ...updProf, ...val })
	}
	const validateName = (name: string) => {
		setStateUserAddFormValue({ name })
	}
	const validateChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStateUserAddFormValue({ is_gpt_user: event.target.checked })
	}
	const finalValid = () => {
		updateUser(updProf)
	}
	return (
		<Modal
			open={open}
			onClose={closeModal}
			aria-labelledby="chapter-modal-form"
			aria-describedby="chapter-modal-description"
		>
			<Box sx={{ ...style, width: isNonMobile ? "50%" : "100%" }}>
				<TextInput
					label={USERNAME_TITLE_T}
					value={updProf.name}
					onChange={validateName}
					props={{ multiline: true }}
				/>

				<FormControlLabel
					label={USER_GPT_LABEL_T}
					control={
						<Checkbox
							// color="primary"
							checked={updProf.is_gpt_user}
							onChange={validateChecked}
							inputProps={{ "aria-label": "is_checked" }}
							sx={{
								color: pink[800],
								"&.Mui-checked": {
									color: pink[600],
								},
							}}
						/>
					}
				/>

				<Button
					onClick={finalValid}
					sx={{
						color: theme.palette.text.primary,
					}}
				>
					{SUBMIT_T}
				</Button>
			</Box>
		</Modal>
	)
}

const style = {
	position: "absolute" as "absolute",
	display: "flex",
	flexDirection: "column",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// width: "50%",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default ModalUpdateFormUser
