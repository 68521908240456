import React, { FC, useEffect, useState } from "react"
import { QuestionsType } from "../../../../TypesComp/TypesComp"
import { ItemStack } from "../../../../theme/styledComponents"
import { useTheme } from "@mui/material"

interface QuestionPresentElemProps {
	question: QuestionsType
	selectQuestion(q: QuestionsType): void
	is_current: boolean
	ondblclick(): void
}
const QuestionPresentElem: FC<QuestionPresentElemProps> = ({
	selectQuestion,
	question,
	is_current,
	ondblclick,
}) => {
	const theme = useTheme()
	const [selColor, setSelColor] = useState(theme.palette.primary.main)
	useEffect(() => {
		if(!question.is_check){
			setSelColor("darkred")
		}else{
			setSelColor(theme.palette.primary.main)
		}
		is_current && setSelColor("green") 
	}, [is_current, theme.palette.primary.main, question])

	const handleDoubleClick = () => {
		ondblclick()
	}
	return (
		<ItemStack
			onClick={() => selectQuestion(question)}
			sx={{ border: `1px solid ${selColor}` }}
			onDoubleClick={handleDoubleClick}
		>
			{question.name}
		</ItemStack>
	)
}

export default QuestionPresentElem
