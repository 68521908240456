import { Container, styled } from "@mui/material"
import React, { useEffect, useState } from "react"
// import GenericModal from "../../../components/modals/GenericModal"
// import s from "./CalendarNew.module.css"
import Month from "./Month"
import NavigateBlock from "./NavigateBlock"
import SelectedPeriodMod from "./selectedPeriod/SelectedPeriodMod"
import GenericModal from "./modal/GenericModal"
// import GenericModal from "../../../components/genericModal/GenericModal";

export type DateWithEventColorType = {
	date: Date
	colors: Array<string | undefined>
}
export type SecondEventsType = {
	days: string
	date?: string
	color: string
}

interface CalendarNewProps {
	processingTheSelectedDate: (date: Date) => void
	dateWithEventsArray: Array<DateWithEventColorType>
	secondEventsArray?: SecondEventsType[]
	languageDesignation: string
}

const CalendarContainer = styled(Container)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	borderRadius: "15px",
	padding: "10px",
}))
const CalendarNew: React.FC<CalendarNewProps> = ({
	processingTheSelectedDate,
	dateWithEventsArray,
	secondEventsArray = [],
	languageDesignation,
}) => {
	const [selectedYear, setSelectedYear] = useState<number>(
		new Date().getFullYear()
	)
	const [selectedMonth, setSelectedMonth] = useState<number>(
		new Date().getMonth()
	)
	const [selectedDate, setSelectedDate] = useState<Date>(new Date())
	const [IsSelectMod, setIsSelectMod] = useState<boolean>(false)
	const [eventsInMonth, setEventsInMonth] = useState<
		Array<DateWithEventColorType>
	>([
		...dateWithEventsArray.filter(
			(ev) =>
				ev.date.getMonth() === selectedMonth &&
				ev.date.getFullYear() === selectedYear
		),
	])

	useEffect(() => {
		setSelectedYear(selectedDate.getFullYear())
		setSelectedMonth(selectedDate.getMonth())
	}, [selectedDate])
	useEffect(() => {
		setEventsInMonth([
			...dateWithEventsArray.filter(
				(ev) =>
					ev.date.getMonth() === selectedMonth &&
					ev.date.getFullYear() === selectedYear
			),
		])
		setIsSelectMod(false)
	}, [selectedMonth, selectedYear, dateWithEventsArray])

	useEffect(() => {
		processingTheSelectedDate(selectedDate)
	}, [selectedDate, processingTheSelectedDate])

	const cancelSelect = () => {
		setSelectedDate(new Date())
		setIsSelectMod(false)
	}
	const onsubmitSelect = () => {
		setIsSelectMod(false)
	}

	return (
		<>
			<CalendarContainer>
				<NavigateBlock
					selectedMonth={selectedMonth}
					languageDesignation={languageDesignation}
					selectedYear={selectedYear}
					setSelectedMonth={setSelectedMonth}
					setSelectedYear={setSelectedYear}
					setIsSelectMod={setIsSelectMod}
					setToday={cancelSelect}
				/>
				{/*<div style={{width:'1000px', backgroundColor:'darkgreen', height:'5px'}}></div>*/}
				<Month
					selectedDate={selectedDate}
					secondEventsArray={secondEventsArray}
					setSelectedDate={setSelectedDate}
					selectedYear={selectedYear}
					selectedMonth={selectedMonth}
					languageDesignation={languageDesignation}
					eventsInMonth={eventsInMonth}
				/>
			</CalendarContainer>

			{IsSelectMod && (
				<GenericModal closeModal={cancelSelect}>
					<SelectedPeriodMod
						selectedMonth={selectedMonth}
						setSelectedMonth={setSelectedMonth}
						languageDesignation={languageDesignation}
						selectedYear={selectedYear}
						setSelectedYear={setSelectedYear}
					/>
				</GenericModal>
			)}
		</>
	)
}

export default CalendarNew
