import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import PartOnePage from "./pages/basePages/PartOnePage"
import PartTwoPage from "./pages/basePages/PartTwoPage"
import PartThreePage from "./pages/basePages/PartThreePage"
import PartFourPage from "./pages/basePages/PartFourPage"
import AdminToolsWrapper from "./pages/adminsTools/AdminToolsWrapper"

// import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
// import { getAuth } from "firebase/auth"
// import { getMessaging, onMessage } from "firebase/messaging"
// import { getFirestore } from "firebase/firestore"
// import { FBContextType } from "./TypesComp/TypesComp"

// const firebaseConfig = {
// 	apiKey: "AIzaSyANjtTXrtMGOQcMJ8At1ajzZty4AZi11Vs",
// 	authDomain: "gbsproject-cded9.firebaseapp.com",
// 	projectId: "gbsproject-cded9",
// 	storageBucket: "gbsproject-cded9.appspot.com",
// 	messagingSenderId: "283418870405",
// 	appId: "1:283418870405:web:32f080bbfd5f344a849fec",
// 	measurementId: "G-H6BX54K1S3",
// }

// Initialize Firebase
// const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
// const auth = getAuth(app)
// const messaging = getMessaging(app)
// const db = getFirestore(app)

// export const FBContext = React.createContext<FBContextType>({
// 	app,
// 	auth,
// 	db,
// 	analytics,
// 	messaging,
// })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	<React.StrictMode>
		{/* <FBContext.Provider value={{ app, analytics, auth, db, messaging }}> */}
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<PartOnePage />} />
					<Route path="/ptwop" element={<PartTwoPage />} />
					<Route path="/pthrp" element={<PartThreePage />} />
					<Route path="/pfourp" element={<PartFourPage />} />
					<Route path="/admp" element={<AdminToolsWrapper />} />
				</Route>
			</Routes>
		</BrowserRouter>
		{/* </FBContext.Provider> */}
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
