import {
	defaultDelete,
	defaultGet,
	defaultPost,
	defaultPut,
} from "./PostService"

/* profile */
export const getProfileDataFromBase = () => {
	return defaultGet("accounts/me/", {})
		.then((response) => {
			// console.log("request getProfile", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.statusText
		})
}
export const getUsers = () => {
	return defaultGet("accounts/profiles/", {})
		.then((response) => {
			// console.log("request getUsers", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.statusText
		})
}
export const putUserProfileToBase = (id: number, data: object) => {
	return defaultPut("accounts/profiles/" + id.toString() + "/", data)
		.then((response) => {
			return response.data
		})
		.catch((err) => {
			//console.log(err)
			return err.response.statusText
		})
}
export const delAcc = (data: object) => {
	return defaultPost("accounts/del/", data)
		.then((response) => {
			return response.status
		})
		.catch((err) => {
			return err.response.message
		})
}

export const createAcc = (data: object) => {
	return defaultPost("auth/users/", data)
		.then((response) => {
			return response
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}
export const createAccPutOwner = (data: object) => {
	return defaultPost("accounts/putOwner/", data)
		.then((response) => {
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}
export const postCheckMe = (data: object) => {
	return defaultPost("accounts/checkMe/", data)
		.then((response) => {
			return response
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

// Subjects
export const getSubjectsFromBase = () => {
	return defaultGet("education/subjects/", {})
		.then((response) => {
			// console.log("request getSubjects", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.status
		})
}

// Chapters chapters sub
export const getChaptersFromBase = (sub_id: number) => {
	return defaultGet("education/chapters/", { params: { sub: sub_id } })
		.then((response) => {
			// console.log("request getChapters", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.status
		})
}
export const addChapterToBase = (data: object) => {
	return defaultPost("education/chapters/", data)
		.then((response) => {
			// console.log("request addChapter", response.data)
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}
export const putChapterToBase = (id: number, data: object) => {
	return defaultPut("/education/chapters/" + id.toString() + "/", data)
		.then((response) => {
			// console.log("request putChapterToBase", response.data)
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

// Quations questions chap
export const getQuestionsFromBase = (
	chap_id: number | string,
	sub_id: number
) => {
	return defaultGet("education/questions/", {
		params: { chap: chap_id, sub: sub_id },
	})
		.then((response) => {
			// console.log("request getQuestions", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.status
		})
}
export const get_post_random = (data: object) => {
	return defaultPost("education/get_post_random/", data)
		.then((response) => {
			// console.log("request getQuestions", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.status
		})
}
export const addQuestionsToBase = (data: object) => {
	return defaultPost("education/questions/", data)
		.then((response) => {
			// console.log("request addQuestions", response.data)
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

export const putQuestionsToBase = (id: number, data: object) => {
	return defaultPut("/education/questions/" + id.toString() + "/", data)
		.then((response) => {
			// console.log("request putQuestionsToBase", response.data)
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

// Answers answers quest
export const getAnswersFromBase = (quest_id: number) => {
	return defaultGet("education/answers/", { params: { quest: quest_id } })
		.then((response) => {
			// console.log("request getAnswers", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.statusText
		})
}

export const addAnswersToBase = (data: object) => {
	return defaultPost("education/answers/", data)
		.then((response) => {
			// console.log("request addAnswersToBase", response.data)
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

export const putAnswerToBase = (id: number, data: object) => {
	return defaultPut("/education/answers/" + id.toString() + "/", data)
		.then((response) => {
			// console.log("request putAnswerToBase", response.data)
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

// Statistics statistics stat

export const getStatisticFromBase = (us_id: number) => {
	return defaultGet("education/statistics/", { params: { us_id: us_id } })
		.then((response) => {
			// console.log("request getStatistic", response.data)
			return response.data
		})
		.catch((err) => {
			// console.error(err.response.statusText, err.response.status)
			return err.response.statusText
		})
}
export const addStatisticsToBase = (data: object) => {
	return defaultPost("education/statistics/", data)
		.then((response) => {
			// console.log("request addStatisticsToBase", response.data)
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

export const sendChatMsg = (data: object) => {
	return defaultPost("chat/s_msg/", data)
		.then((response) => {
			return response.data
		})
		.catch((err) => {
			// console.log(err)
			return err.response.statusText
		})
}

