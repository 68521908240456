import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { QuestionsType } from "../../../../TypesComp/TypesComp"
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	Input,
	InputAdornment,
	Modal,
	Typography,
} from "@mui/material"
import TextInput from "../../../../components/inputs/TextInput"
import {
	CANCEL_T,
	QUESTION_EXIST_TITLE_T,
	QUESTION_NAME_T,
	SAVE_T,
	SUBMIT_T,
} from "../../../../CONSTANTS/constsTrans"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import { pink } from "@mui/material/colors"
import FlexEvenly from "../../../../components/flex-evenly"
import ClearIcon from "@mui/icons-material/Clear"
import DoneIcon from "@mui/icons-material/Done"
import { FlexBetween } from "../../../../theme/styledComponents"
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined"

interface QuestionsFormProps {
	question: QuestionsType | undefined
	questions: QuestionsType[]
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	addQuation(qa: QuestionsType): void
}
const QuationForm: FC<QuestionsFormProps> = ({
	questions,
	question,
	open,
	setOpen,
	addQuation,
}) => {

	const [isExists, setIsExists] = useState<boolean>(false)
	const [nQuestion, setNQuestion] = useState<QuestionsType>({
		name: "",
		number: NaN,
		chapter: NaN,
		is_active: true,
		is_check: false,
		file: undefined,
	})
	const [imageName, setImageName] = useState<string>()

	useEffect(() => {
		if (nQuestion?.image) {
			setImageName(nQuestion?.image.split("/").at(-1))
		}
	}, [nQuestion])

	useEffect(() => {
		if (question) {
			setNQuestion(question)
		}
	}, [question])
	const isLoading = false

	const setStateQuestionValue = (val: Partial<QuestionsType>) => {
		nQuestion && setNQuestion({ ...nQuestion, ...val })
	}

	const validateName = (name: string) => {
		setStateQuestionValue({ name })

		// setIsExists(false)
	}

	const validateActive = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStateQuestionValue({ is_active: event.target.checked })
	}

	const validateChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStateQuestionValue({ is_check: event.target.checked })
	}

	const cancel = () => {
		setOpen(false)
	}

	const handlePaste = (text: string) => {
		const qf = questions.filter((foo) => text.includes(foo.name))
		if (qf.length > 0) {
			setIsExists(true)
		}
	}

	const clearName = () => {
		validateName("")
		setIsExists(false)
	}

	const handleChangeImg = (e: any) => {
		nQuestion && setNQuestion({ ...nQuestion, file: e.target.files[0] })
	}

	const onSubmit = () => {
		!isExists &&
			addQuation({
				id: question?.id,
				name: nQuestion.name,
				is_active: nQuestion.is_active,
				chapter: nQuestion.chapter,
				is_check: nQuestion.is_check,
				file: nQuestion.file,
			})
	}
	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="chapter-modal-form"
			aria-describedby="chapter-modal-description"
			container={() => document.getElementsByClassName("fullscreen")[0]}
		>
			<Box sx={style}>
				{isExists && (
					<FlexEvenly>
						<Typography>{QUESTION_EXIST_TITLE_T}</Typography>
						<IconButton aria-label="delete" onClick={() => clearName()}>
							<ClearIcon />
						</IconButton>
						<IconButton aria-label="delete" onClick={() => setIsExists(false)}>
							<DoneIcon />
						</IconButton>
					</FlexEvenly>
				)}

				<TextInput
					label={QUESTION_NAME_T}
					value={nQuestion.name}
					onChange={validateName}
					onPaste={handlePaste}
					props={{ multiline: true }}
				/>

				<FlexBetween>
					<Box>
						<FormControlLabel
							label="is checked"
							control={
								<Checkbox
									// color="primary"
									checked={nQuestion.is_check}
									onChange={validateChecked}
									inputProps={{ "aria-label": "is_checked" }}
									sx={{
										color: pink[800],
										"&.Mui-checked": {
											color: pink[600],
										},
									}}
								/>
							}
						/>
						<FormControlLabel
							label="is active"
							control={
								<Checkbox
									// color="primary"
									checked={nQuestion.is_active}
									onChange={validateActive}
									inputProps={{ "aria-label": "is_active" }}
									sx={{
										color: pink[800],
										"&.Mui-checked": {
											color: pink[600],
										},
									}}
								/>
							}
						/>
					</Box>

					<FormControl>
						<Input
							type="file"
							startAdornment={
								<InputAdornment position="start">
									<ImageOutlinedIcon />
								</InputAdornment>
							}
							onChange={handleChangeImg}
						/>
						{imageName}
					</FormControl>
					{/* {!!nQuestion?.image && (
						<Typography color={"success"}>{imageName}</Typography>
					)} */}

					{/* <IconButton>
						<DownloadIcon />
					</IconButton> */}
				</FlexBetween>

				<ButtonGroup variant="outlined" aria-label="loading button group">
					<Button onClick={cancel}>{CANCEL_T}</Button>

					<LoadingButton
						loading={isLoading}
						loadingPosition="start"
						startIcon={<SaveIcon />}
						onClick={onSubmit}
					>
						{isLoading ? SAVE_T : SUBMIT_T}
					</LoadingButton>
				</ButtonGroup>
			</Box>
		</Modal>
	)
}

const style = {
	position: "absolute" as "absolute",
	display: "flex",
	flexDirection: "column",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default QuationForm
