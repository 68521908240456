import React, { useContext, useEffect, useState } from "react"
// import {
// 	WorkoutsType,
// 	PeriodEventsInterface,
// } from "../../source/TypesCollection"
import { useNavigate } from "react-router-dom"
import CalendarNew, {
	DateWithEventColorType,
	SecondEventsType,
} from "./calendarNew/CalendarNew"
import InfoByDate from "./infoByDate/InfoByDate"
import { Container } from "@mui/material"
import { MainContext } from "../../App"
import { PeriodEventsInterface } from "../../TypesComp/TypesComp"
import { LOCALE_DATE_PARAM_STRING_FR } from "../../CONSTANTS/constsSetting"
// import AddIconButton from "../../components/addIconBtn/AddIconButton"

const calendarPageWrapper = {
	marginTop: "5px",
	display: "flex",
	/* width: 100%; */
	flexDirection: "column",
	flexWrap: "nowrap",
	alignItems: "center",
}
const CalendarPage = () => {
	const mainContext = useContext(MainContext)
	const navigate = useNavigate()
	const [dateWithEventsArray, setDateWithEventsArray] = useState<
		Array<DateWithEventColorType>
	>([])
	const [secondEventsArray, setSecondEventsArray] = useState<
		Array<SecondEventsType>
	>([])
	const [selectedDate, setSelectedDate] = useState<Date>(new Date())
	// const [showingWorkouts, setShowingWorkouts] = useState<Array<WorkoutsType>>(
	// 	[]
	// )
	const [showingEvents, setShowingEvents] = useState<
		Array<PeriodEventsInterface>
	>([])

	// useEffect(() => {
	// 	mainContext?.events && setDateWithEventsArray(mainContext?.events)
	// }, [mainContext?.events])

	useEffect(() => {
		mainContext?.setSelectedDate && mainContext?.setSelectedDate(selectedDate)
	}, [selectedDate, mainContext?.setSelectedDate])

	// useEffect(() => {
	// 	!!mainContext && setSecondEventsArray([...mainContext.events])
	// }, [mainContext, mainContext?.events]) // setSecondEventsArray

	// useEffect(() => {
	// 	/* Фильтрует евенты , где в периодических евентах включен день недели выбранной даты ,
	//     а в не периодических - дата соответствует выбранной */
	// 	!!mainContext &&
	// 		setShowingEvents([
	// 			...mainContext.eventsWorkouts.filter(
	// 				(ev) =>
	// 					ev.days.split(",").includes(selectedDate.getDay().toString()) ||
	// 					(ev.date &&
	// 						!ev.isPeriodEvent &&
	// 						new Date(ev.date).setHours(0, 0, 0, 0) ===
	// 							selectedDate.setHours(0, 0, 0, 0))
	// 			),
	// 		])
	// }, [mainContext, mainContext?.eventsWorkouts, selectedDate]) // setShowingEvents

	useEffect(() => {
		const getColorFromDescription = (description:string|undefined) => {
			const dr =  Number(description?.split(",")[1].split(":")[1])
			if(!Number.isNaN(dr)){

				return (dr >= 85.7?"green":"darkred")
			}

			return "darkblue"
		}
		let dateList: Set<string> = new Set<string>()
		let arrayEvents: DateWithEventColorType[] = []
		mainContext?.statistics.forEach((statistic) => {
			dateList.add(
				statistic.date?
				statistic.date.toLocaleDateString(LOCALE_DATE_PARAM_STRING_FR):
				new Date().toLocaleDateString(LOCALE_DATE_PARAM_STRING_FR))
		})
		dateList.forEach((date) => {
			let filterArr = mainContext?.statistics.filter(
				(statistic) => statistic.date?.toLocaleDateString(LOCALE_DATE_PARAM_STRING_FR) === date
			)
			arrayEvents.push({
				date: new Date(date),
				colors: filterArr
					? filterArr.map((statistic) => getColorFromDescription(statistic?.description))
					: [],
			})
		})
		setDateWithEventsArray(arrayEvents)
	}, [mainContext?.statistics]) // setDateWithEventsArray

	// useEffect(() => {
	// 	!!mainContext &&
	// 		setShowingWorkouts(
	// 			mainContext.workouts.filter((training) => {
	// 				if (training.date) {
	// 					return (
	// 						new Date(training.date).toLocaleDateString() ===
	// 						new Date(selectedDate).toLocaleDateString()
	// 					)
	// 				}
	// 				return null
	// 			})
	// 		)
	// }, [selectedDate, mainContext?.workouts, mainContext]) // setShowingWorkouts

	const processingTheSelectedDate = (date: Date) => {
		setSelectedDate(date)
	}
	// const addNewWrkOnDate = () => {
	// 	navigate("/wrk/new", {
	// 		state: {
	// 			date: selectedDate.toLocaleDateString(["fr-CA"]),
	// 			time: new Date().toLocaleTimeString().slice(0, -3),
	// 			path: "/cal",
	// 		},
	// 	})
	// }

	return (
		<Container sx={calendarPageWrapper}>
			<CalendarNew
				processingTheSelectedDate={processingTheSelectedDate}
				languageDesignation={"ru"}
				secondEventsArray={secondEventsArray}
				dateWithEventsArray={dateWithEventsArray}
			/>
			{/* <InfoByDate
				// showingWorkouts={[]}
				// showingEvents={showingEvents}
				selectedDate={selectedDate}
			/> */}

			{/* <AddIconButton action={addNewWrkOnDate} /> */}
		</Container>
	)
}

export default CalendarPage
