import ru from "./ru.json"
import en from "./en.json"

let userLanguages: string[] = ["en"]

export const changeLanguage = (designation: string) => {
	userLanguages[0] = designation
}

export const getTrans = (text: string) => {
	let splitStr = text.split(".")
	if (userLanguages[0] === "en") {
		// @ts-ignore
		return !!en[splitStr[0]][splitStr[1]]? en[splitStr[0]][splitStr[1]]
			: splitStr[1]
	} else if (userLanguages[0] === "fr") {
		// @ts-ignore
		return !!fr[splitStr[0]][splitStr[1]] ? fr[splitStr[0]][splitStr[1]]
			: splitStr[1]
	} else if (userLanguages[0] === "ru") {
		// @ts-ignore
		return !!ru[splitStr[0]][splitStr[1]] ? ru[splitStr[0]][splitStr[1]]
			: splitStr[1]
	} else {
		return splitStr.slice(1).toString()
	}
}
