import { createContext, useState, useMemo, useEffect } from "react"
import { createTheme, Theme } from "@mui/material"

declare module "@mui/material/styles/createPalette" {
	interface Palette {
		workspace: Palette["primary"]
		backgroundSelected: Palette["primary"]
		boxShadow: Palette["primary"]
	}
	interface PaletteOptions {
		workspace: PaletteOptions["primary"]
		backgroundSelected: PaletteOptions["primary"]
		boxShadow: PaletteOptions["primary"]
	}
}

export const tokens = (mode: "dark" | "light") => ({
	...(mode === "dark"
		? {
				background: {
					DEFAULT: "#000000",
					workspace: "#2a2929",
					paper: "#3d243b",
					elem: "#3d243b",
					extModal: "#00000080",
					innerModal: "#FFFFFF80",
					selected: "#520505",
					success: "#FFFFFF",
					button1: "#520505",
				},
				text: {
					DEFAULT: "#FFFFFF",
					inElem: "#bdbdbd",
					selectElem: "#fff",
					success: "#27AC69",
					info: "#fab662b3",
					error: "#ff0000",
					link: "#0cb1ff",
				},
				dote: {
					success: "#FFFFFF",
				},
				boxShadow: {
					DEFAULT: "#007bff80",
				},
		  }
		: {
				background: {
					DEFAULT: "#f9eebc",
					workspace: "#FFFFFF",
					// workspace: "#f1e4a5",
					paper: "#FFFFFF",
					elem: "#777a79",
					extModal: "#FFFFFF80",
					innerModal: "#00000080",
					selected: "#f0fff0",
					success: "#27AC69",
					button1: "#f1e4a5",
				},
				text: {
					DEFAULT: "#000000",
					inElem: "#bdbdbd",
					selectElem: "#fff",
					success: "#27AC69",
					info: "#e3a20a",
					error: "#ff0000",
					// action: "#fff",
					link: "#007bff80",
				},
				dote: {
					success: "#27AC69",
				},
				boxShadow: {
					DEFAULT: "#27AC69",
				},
		  }),
})

export const themeSettings: any = (mode: "dark" | "light") => {
	//  primary?: PaletteColorOptions;
	//   secondary?: PaletteColorOptions;
	//   error?: PaletteColorOptions;
	//   warning?: PaletteColorOptions;
	//   info?: PaletteColorOptions;
	//   success?: PaletteColorOptions;
	//   mode?: PaletteMode;
	//   tonalOffset?: PaletteTonalOffset;
	//   contrastThreshold?: number;
	//   common?: Partial<CommonColors>;
	//   grey?: ColorPartial;
	//   text?: Partial<TypeText>;
	//   divider?: string;
	//   action?: Partial<TypeAction>;
	//   background?: Partial<TypeBackground>;
	//   getContrastText?: (background: string) => string;
	const colors = tokens(mode)
	return {
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						background: colors.background.paper,
						color: colors.text.DEFAULT,
					},
					outlined: {
						background: colors.background.paper,
					},
					text: {
						background: "none",
					},
				},
			},
			MuiContainer: {
				styleOverrides: {
					root: {
						background: colors.background.DEFAULT,
						color: colors.text.DEFAULT,
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						background: colors.background.workspace,
						color: colors.text.DEFAULT,
					},
				},
			},
		},
		palette: {
			mode: mode,
			...{
				primary: {
					main: colors.background.elem,
				},
				secondary: {
					main: colors.text.inElem,
					light: colors.text.selectElem,
					dark: colors.background.button1,
				},
				success: {
					main: colors.text.success,
				},
				text: {
					primary: colors.text.DEFAULT,
					info: colors.text.info,
					success: colors.text.success,
					secondary: colors.text.inElem,
					error: colors.text.error,
					link: colors.text.link,
				},
				background: {
					default: colors.background.DEFAULT,
					paper: colors.background.paper,
					modal: colors.background.innerModal,
				},
				workspace: {
					main: colors.background.workspace,
				},
				boxShadow: {
					main: colors.boxShadow.DEFAULT,
				},
				backgroundSelected: {
					main: colors.background.selected,
				},
			},
		},
	}
}

export const ColorModeContext = createContext({
	toggleColorMode: () => {},
})

export const useMode: any = () => {
	const [mode, setMode] = useState<"dark" | "light">("dark")
	// const colors = tokens(mode)
	useEffect(() => {
		let m = localStorage.getItem("colorMode")
		if (m && (m === "dark" || m === "light")) {
			setMode(m)
		} else {
			setMode("dark")
		}
	}, [])

	const colorMode = useMemo(
		() => ({
			toggleColorMode: () =>
				// setMode((prev) => (prev === "light" ? "dark" : "light")),
				setMode((prev) => {
					if (prev === "light") {
						localStorage.setItem("colorMode", "dark")
						return "dark"
					} else {
						localStorage.setItem("colorMode", "light")
						return "light"
					}
				}),
		}),
		[]
	)

	const theme: Theme = useMemo(
		() => createTheme(themeSettings(mode)),

		[mode]
	)

	return [theme, colorMode]
}

// import { createContext, useState, useMemo } from "react"
// import { createTheme } from "@mui/material"

// export const tokens = (mode: "dark" | "light") => ({
// 	...(mode === "dark"
// 		? {
// 				primary: {
// 					DEFAULT: "#000000",
// 					100: "#000000",
// 					200: "#000000",
// 					300: "#000000",
// 					400: "#000000",
// 					500: "#0F0E0E",
// 					600: "#232323",
// 					700: "#3D3D3D",
// 					800: "#525252",
// 					900: "#5C5C5C",
// 				},
// 				secondary: {
// 					DEFAULT: "#7C7C7C",
// 				},
// 				black: {
// 					DEFAULT: "#000000",
// 					100: "#000000",
// 					200: "#000000",
// 					300: "#000000",
// 					400: "#000000",
// 					500: "#0F0E0E",
// 					600: "#292929",
// 					700: "#3D3D3D",
// 					800: "#525252",
// 					900: "#5C5C5C",
// 				},
// 				white: {
// 					DEFAULT: "#FFFFFF",
// 					100: "#F7F7F7",
// 				},
// 				gray: {
// 					DEFAULT: "#3C3C3C",
// 				},
// 				accentMain: "#0F0E0E",
// 				borderColor: "#3C3C3C",
// 		  }
// 		: {
// 				white: {
// 					DEFAULT: "#FFFFFF",
// 					100: "#F7F7F7",
// 					200: "#D1D1D1",
// 				},
// 				primary: {
// 					DEFAULT: "#FFFFFF",
// 					500: "#F7F7F7",
// 				},
// 				secondary: {
// 					DEFAULT: "#7C7C7C",
// 				},
// 				black: {
// 					DEFAULT: "#000000",
// 					100: "#525252",
// 					200: "#3D3D3D",
// 					300: "#292929",
// 					400: "#141414",
// 					500: "#000000",
// 					600: "#000000",
// 					700: "#000000",
// 					800: "#000000",
// 					900: "#000000",
// 				},
// 				gray: {
// 					DEFAULT: "#3C3C3C",
// 				},
// 				accentMain: "#F7F7F7",
// 				borderColor: "#D1D1D1",
// 		  }),
// })

// export const themeSettings: any = (mode: "dark" | "light") => {
// 	//  primary?: PaletteColorOptions;
// 	//   secondary?: PaletteColorOptions;
// 	//   error?: PaletteColorOptions;
// 	//   warning?: PaletteColorOptions;
// 	//   info?: PaletteColorOptions;
// 	//   success?: PaletteColorOptions;
// 	//   mode?: PaletteMode;
// 	//   tonalOffset?: PaletteTonalOffset;
// 	//   contrastThreshold?: number;
// 	//   common?: Partial<CommonColors>;
// 	//   grey?: ColorPartial;
// 	//   text?: Partial<TypeText>;
// 	//   divider?: string;
// 	//   action?: Partial<TypeAction>;
// 	//   background?: Partial<TypeBackground>;
// 	//   getContrastText?: (background: string) => string;
// 	const colors = tokens(mode)
// 	return {
// 		palette: {
// 			mode: mode,
// 			...(mode === "dark"
// 				? {
// 						primary: {
// 							main: colors.primary.DEFAULT,
// 							dark: colors.primary[200],
// 						},
// 						secondary: {
// 							main: colors.secondary.DEFAULT,
// 						},
// 						//
// 						// neutral: {
// 						// 	dark: colors.black[500],
// 						// 	light: colors.white[100],
// 						// },
// 				  }
// 				: {
// 						primary: {
// 							main: colors.primary.DEFAULT,
// 							dark: colors.primary[500],
// 						},
// 						secondary: {
// 							main: colors.secondary.DEFAULT,
// 						},
// 						// neutral: {
// 						// 	dark: colors.black[500],
// 						// 	light: colors.white[100],
// 						// },
// 				  }),
// 		},
// 		typography: {
// 			fontFamily: ["Poppins", "sans-serif"].join(","),
// 			fontSize: 14,
// 			h1: {
// 				fontFamily: ["Poppins", "sans-serif"].join(","),
// 				fontSize: 28,
// 			},
// 			h2: {
// 				fontFamily: ["Poppins", "sans-serif"].join(","),
// 				fontSize: 20,
// 			},
// 			h3: {
// 				fontFamily: ["Poppins", "sans-serif"].join(","),
// 				fontSize: 18,
// 			},
// 			body1: "p",
// 			body2: "span",
// 		},
// 	}
// }

// export const ColorModeContext: any = createContext({
// 	toggleColorMode: () => {},
// })

// export const useMode = () => {
// 	const [mode, setMode] = useState("dark")

// 	const colorMode = useMemo(
// 		() => ({
// 			toggleColorMode: () =>
// 				setMode((prev) => (prev === "light" ? "dark" : "light")),
// 		}),
// 		[]
// 	)

// 	const theme: any = useMemo(() => createTheme(themeSettings(mode)), [mode])

// 	return [theme, colorMode]
// }
