import { Box, ButtonGroup, IconButton } from "@mui/material"
import React, { FC, useContext, useState } from "react"
import { FlexBetween } from "../../../theme/styledComponents"
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined"
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined"
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined"
import { UserAddFormType, UserProfileType } from "../../../TypesComp/TypesComp"
import ModalAddForm from "../../adminsTools/ModalAddForm"
import { MainContext } from "../../../App"
import ProfilesListMobile from "./ProfilesListMobile"
import ProfileTestBlockMobile from "./ProfileTestBlockMobile"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"

interface ProfilesWrapperMobileProps {
	showAddUserForm(): void
	switchMode(mode: "test" | "chatGPT"): void
	mode: "test" | "chatGPT"
	handleSelectUser(user: UserProfileType): void
	currentProfile: UserProfileType | undefined
	isOpen: boolean
	closeModal(ob: UserAddFormType | undefined): void
}
const ProfilesWrapperMobile: FC<ProfilesWrapperMobileProps> = ({
	showAddUserForm,
	switchMode,
	mode,
	handleSelectUser,
	currentProfile,
	isOpen,
	closeModal,
}) => {
	const mainContext = useContext(MainContext)
	const [isShowCal, setIsShowCal] = useState<boolean>(false)

	const switchVisibleCalendar = () => {
		setIsShowCal((prev) => !prev)
	}

	return (
		<Box>
			<FlexBetween>
				<IconButton onClick={showAddUserForm} color="info">
					<PersonAddAltOutlinedIcon />
				</IconButton>
				<ButtonGroup variant="outlined" aria-label="loading button group">
					<IconButton onClick={switchVisibleCalendar} color={"info"}>
						<CalendarMonthIcon />
					</IconButton>
					<IconButton
						onClick={() => switchMode("test")}
						color={mode === "test" ? "success" : "primary"}
					>
						<LibraryBooksOutlinedIcon />
					</IconButton>
					<IconButton
						onClick={() => switchMode("chatGPT")}
						color={mode === "chatGPT" ? "success" : "primary"}
					>
						<QuizOutlinedIcon />
					</IconButton>
				</ButtonGroup>
			</FlexBetween>
			<Box>
				{!!mainContext && (
					<ProfilesListMobile
						users={mainContext?.users}
						handleSelectUser={handleSelectUser}
						currentProfile={currentProfile}
					/>
				)}
			</Box>
			{mode === "test" && (
				<ProfileTestBlockMobile
					currentProfile={currentProfile}
					isShowCal={isShowCal}
				/>
			)}

			{!!isOpen && <ModalAddForm open={isOpen} closeModal={closeModal} />}
		</Box>
	)
}

export default ProfilesWrapperMobile
