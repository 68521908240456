import React, { FC, useEffect, useState } from "react"
import { ChapterType } from "../../../../TypesComp/TypesComp"
import { useTheme } from "@mui/material"
import { ItemStack } from "../../../../theme/styledComponents"

interface ChapterPresentElemProps {
	chapter: ChapterType
	selectChapter(c: ChapterType): void
	is_current: boolean
	ondblclick(): void
}
const ChapterPresentElem: FC<ChapterPresentElemProps> = ({
	chapter,
	selectChapter,
	is_current,
	ondblclick,
}) => {
	const theme = useTheme()
	const [selColor, setSelColor] = useState(theme.palette.primary.main)

	useEffect(() => {
		is_current ? setSelColor("green") : setSelColor(theme.palette.primary.main)
	}, [is_current, theme.palette.primary.main])

	const handleDoubleClick = () => {
		ondblclick()
	}

	return (
		<ItemStack
			onClick={() => selectChapter(chapter)}
			onDoubleClick={handleDoubleClick}
			sx={{ border: `1px solid ${selColor}` }}
		>
			{chapter.name}
		</ItemStack>
	)
}

export default ChapterPresentElem
