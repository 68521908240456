import { Box, Divider, IconButton, Typography } from "@mui/material"
import React, { Dispatch, FC, SetStateAction, useContext, useState } from "react"
// import FlexBetween from "../../../components/flex-between"
import AddIcon from "@mui/icons-material/Add"
import { QUESTIONS_T } from "../../../CONSTANTS/constsTrans"
import { ChapterType, QuestionsType } from "../../../TypesComp/TypesComp"
import {
	addQuestionsToBase,
	putQuestionsToBase,
} from "../../../API/RequestServices"
import QuationForm from "./forms/QuationForm"
import { ListBlock } from "../../../theme/styledComponents"
import QuestionPresentElem from "./presentElems/QuestionPresentElem"
import { sortQuestionsByName } from "../../../BLL/sorts"
import FlexEvenly from "../../../components/flex-evenly"
import { MainContext } from "../../../App"

interface QuationsPageProps {
	currentChapter: ChapterType | undefined
	questions: QuestionsType[]
	setQuestions: Dispatch<SetStateAction<QuestionsType[]>>
	currentQuestion: QuestionsType | undefined
	chapters: ChapterType[]
	// setCurrentQuestion: Dispatch<SetStateAction<QuestionsType | undefined>>
	selectQuestion(q: QuestionsType | undefined): void
	addQuation(q: QuestionsType | undefined): void
}
const QuationsPage: FC<QuationsPageProps> = ({
	currentChapter,
	setQuestions,
	currentQuestion,
	chapters,
	questions,
	selectQuestion,
	addQuation,
}) => {
	const mainContext = useContext(MainContext)
	const [isOpenForm, setIsOpenForm] = useState<boolean>(false)

	// const addQuation = (nQuation: QuestionsType) => {
	// 	const addQuatToBase = async () => {
	// 		const formData = new FormData()
	// 		!!nQuation.file &&
	// 			formData.append("image", nQuation.file, nQuation.file.name)
	// 		formData.append("name", nQuation.name)
	// 		formData.append("chapter", currentChapter!.id!.toString())
	// 		formData.append("is_active", nQuation.is_active.toString())
	// 		formData.append("is_check", nQuation.is_check.toString())

	// 		if (!!nQuation.id) {
	// 			await putQuestionsToBase(nQuation.id, formData).then(
	// 				(quat: QuestionsType) => {
	// 					if (typeof quat === "string") {
	// 						// TODO обработка ошибки
	// 					} else {
	// 						setQuestions((prev) => [
	// 							...prev.filter((elem) => elem.id !== quat.id),
	// 							quat,
	// 						])
	// 						selectQuestion(quat)
	// 						setIsOpenForm(false)
	// 					}
	// 				}
	// 			)
	// 		} else {
	// 			await addQuestionsToBase(formData).then((quat: QuestionsType) => {
	// 				if (typeof quat === "string") {
	// 					// TODO обработка ошибки
	// 				} else {
	// 					setQuestions((prev) => [...prev, quat])
	// 					setIsOpenForm(false)
	// 					selectQuestion(quat)
	// 				}
	// 			})
	// 		}
	// 	}

	// 	!!currentChapter && addQuatToBase()
	// }

	// const selectQuestion = (question: QuestionsType) => {
	// 	setCurrentQuestion(question)
	// }

	const openFormQuestion = () => {
		mainContext?.currentUser?.is_staff && setIsOpenForm(true)
	}

	const openFormQuestionForAdd = () => {
		if (!!currentChapter && mainContext?.currentUser?.is_staff) {
			selectQuestion(undefined)
			setIsOpenForm(true)
		}
	}

	const addQuation_ = (nQuation: QuestionsType) => {
		addQuation(nQuation)
		setIsOpenForm(false)
	}

	return (
		<Box>
			<FlexEvenly>
				<Typography>
					{QUESTIONS_T} ({questions.length}){" "}
				</Typography>
				{mainContext?.currentUser?.is_staff &&
					<IconButton
						aria-label="add"
						color="success"
						onClick={openFormQuestionForAdd}
					>
						<AddIcon />
					</IconButton>
				}
			</FlexEvenly>

			<ListBlock
				direction={"column"}
				divider={<Divider orientation="vertical" flexItem />}
				spacing={1}
			>
				{sortQuestionsByName(questions).map((question) => {
					return (
						<QuestionPresentElem
							key={question.id}
							question={question}
							ondblclick={openFormQuestion}
							selectQuestion={selectQuestion}
							is_current={question === currentQuestion}
						/>
					)
				})}
			</ListBlock>

			{isOpenForm && (
				<QuationForm
					question={currentQuestion}
					questions={questions}
					open={isOpenForm}
					addQuation={addQuation_}
					setOpen={setIsOpenForm}
				/>
			)}
		</Box>
	)
}

export default QuationsPage
