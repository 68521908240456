import React, { FC, useEffect, useState } from "react"
import { UserProfileType } from "../../../TypesComp/TypesComp"
import { ItemStack } from "../../../theme/styledComponents"
import { Typography, useTheme } from "@mui/material"

interface ProfilePresentProps {
	user: UserProfileType
	selected: boolean
	handleSelectUser(user: UserProfileType): void
	handleDoubleClick(user: UserProfileType): void
}
const ProfilePresent: FC<ProfilePresentProps> = ({
	user,
	selected,
	handleSelectUser,
	handleDoubleClick,
}) => {
	const theme = useTheme()
	const [selColor, setSelColor] = useState(theme.palette.primary.main)

	useEffect(() => {
		setSelColor(!!selected ? theme.palette.success.main : "inherit")
	}, [selected])

	const selectUser_ = (user: UserProfileType) => {
		handleSelectUser(user)
	}

	return (
		<ItemStack
			onClick={() => selectUser_(user)}
			sx={{ border: `2px solid ${selColor}` }}
			onDoubleClick={() => handleDoubleClick(user)}
		>
			{user.name}
		</ItemStack>
	)
}

export default ProfilePresent
