import { Box, styled } from "@mui/material"
import React, { Dispatch, FC, SetStateAction } from "react"
// import { useState, useEffect } from "react"
import QuationsPage from "./TestInterComp/QuationsPage"

// import SubjectsPage from './TestInterComp/SubjectsPage';
import AnswersPage from "./TestInterComp/AnswersPage"
import {
	AnswerType,
	ChapterType,
	QuestionsType,
	SubjectType,
} from "../../TypesComp/TypesComp"
import ChapterPage from "./TestInterComp/ChapterPage"
// import {
// 	getAnswersFromBase,
// 	getQuestionsFromBase,
// } from "../../API/RequestServices"

const FlexBetween = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "flex-start",
})

interface TestInterProps {
	// subjects: SubjectType[]
	chapters: ChapterType[]
	setChapters: Dispatch<SetStateAction<ChapterType[]>>
	currentSubject: SubjectType | undefined
	currentChapter: ChapterType | undefined
	setCurrentChapter: Dispatch<SetStateAction<ChapterType | undefined>>
	// setCurentSubject: Dispatch<SetStateAction<SubjectType | undefined>>
	getQuestions(chapter: ChapterType): void
	selectQuestion(q: QuestionsType | undefined): void
	addQuation(q: QuestionsType | undefined): void
	questions: QuestionsType[]
	currentQuestion: QuestionsType | undefined
	setQuestions: Dispatch<SetStateAction<QuestionsType[]>>
	currentAnswer: AnswerType | undefined
	selectAnswer(answ: AnswerType): void
	answers: AnswerType[]
	setAnswers: Dispatch<SetStateAction<AnswerType[]>>
}
const TestInter: FC<TestInterProps> = ({
	currentSubject,
	chapters,
	setChapters,
	currentChapter,
	setCurrentChapter,
	selectAnswer,
	getQuestions,
	selectQuestion,
	addQuation,
	questions,
	currentQuestion,
	currentAnswer,
	answers,
	setQuestions,
	setAnswers,
}) => {

	const selectChapter = (chapter: ChapterType) => {
		setCurrentChapter(chapter)
		selectQuestion(undefined)
		getQuestions(chapter)
	}


	return (
		<>
			<FlexBetween>
				<Box sx={{ width: "20%" }} p={1}>
					<ChapterPage
						curentSubject={currentSubject}
						currentChapter={currentChapter}
						setCurrentChapter={setCurrentChapter}
						chapters={chapters}
						setChapters={setChapters}
						selectChapter={selectChapter}
					/>
				</Box>

				<Box sx={{ width: "40%" }} p={1}>
					<QuationsPage
						currentChapter={currentChapter}
						questions={questions}
						setQuestions={setQuestions}
						chapters={chapters}
						currentQuestion={currentQuestion}
						selectQuestion={selectQuestion}
						addQuation={addQuation}
					/>
				</Box>

				<Box sx={{ width: "40%" }} p={1}>
					<AnswersPage
						currentQuestion={currentQuestion}
						questions={questions}
						answers={answers}
						setAnswers={setAnswers}
						currentAnswer={currentAnswer}
						selectAnswer={selectAnswer}
					/>
				</Box>
			</FlexBetween>
		</>
	)
}

export default TestInter
