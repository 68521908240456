import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Container,
	Typography,
	useTheme,
} from "@mui/material"
import React, { Dispatch, SetStateAction, useState } from "react"
import { createAcc, postCheckMe } from "../../API/RequestServices"
import PasswordInput from "../../components/inputs/PasswordInput"
// import Brightness4Icon from "@mui/icons-material/Brightness4"
// import Brightness7Icon from "@mui/icons-material/Brightness7"
// import { ColorModeContext } from "../../theme/theme"
import LoginInput from "../../components/inputs/LoginInput"
import { styled } from "@mui/material"
import { MAIN_TITLE_T } from "../../CONSTANTS/constsTrans"
import { NO_REGISTRATION } from "../../CONSTANTS/constsSetting"
import { UserProfileType } from "../../TypesComp/TypesComp"

interface AuthProps {
	setIsAuth: Dispatch<SetStateAction<boolean>>
	setCurrentUser: Dispatch<SetStateAction<UserProfileType | undefined>>
}
interface State {
	mode: "signin" | "signup"
	login: string
	password: string
	confPass?: string
}

const AuthContainer = styled(Container)(({ theme }) => ({
	height: "100vh",
	backgroundColor:
		theme.palette.mode === "dark" ? "black" : theme.palette.primary.main,
	paddingTop: "20px",
}))
const AuthCard = styled(Card)(({ theme }) => ({
	// height: "100vh",
	backgroundColor: theme.palette.background.paper,
	// paddingTop: "20px",
}))

const AuthenticationPage: React.FC<AuthProps> = ({
	setIsAuth,
	setCurrentUser,
}) => {
	const [state, setState] = React.useState<State>({
		mode: "signin",
		password: "",
		login: "",
	})
	const theme = useTheme()
	// const colorMode = React.useContext(ColorModeContext)
	const [errMessage, setErrMessage] = useState<string>()
	const setStateValue = (val: Partial<State>) => setState({ ...state, ...val })

	const validateLogin = (login: string) => setStateValue({ login })
	const validatePass = (password: string) => setStateValue({ password })
	const validateConfPass = (confPass: string) => setStateValue({ confPass })
	const callPostCheckMe = () => {
		const _postCheckMe = async () => {
			return await postCheckMe({
				username: state.login,
				password: state.password,
			})
		}
		_postCheckMe()
			.then((response: any) => {
				if (response.status === 200) {
					localStorage.setItem("Authorization", "Token " + response.data.token)
					confirmRegistration(response.data)
				} else if (response.status === 204) {
					setErrMessage("Unable to log in with provided credentials.")
					localStorage.removeItem("Authorization")
				} else {
					localStorage.removeItem("Authorization")
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}
	const confirmRegistration = (data: UserProfileType) => {
		setCurrentUser(data)
		setIsAuth(true)
	}
	const onSubmit = async () => {
		setErrMessage(undefined)
		if (state.mode === "signin") {
			callPostCheckMe()
		} else {
			if (state.password.length > 7 && state.login.length > 4) {
				if (state.password === state.confPass) {
					await createAcc({
						username: state.login,
						password: state.password,
					}).then((response) => {
						if (response.status === 201) {
							callPostCheckMe()
						} else {
							console.log("status => ", response.status)
						}
					})
				} else {
					setErrMessage("Passwords do not match")
				}
			} else {
				setErrMessage(
					state.login.length < 5
						? "login length must be more than 4 characters"
						: "Password length must be more than 7 characters"
				)
			}
		}
	}
	return (
		<AuthContainer>
			{/* <Box ml={3} mb={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
				<IconButton
					sx={{ ml: 1 }}
					onClick={colorMode.toggleColorMode}
					// color={"inherit"}
				>
					{theme.palette.mode === "dark" ? (
						<Brightness7Icon fontSize="small" color="secondary" />
					) : (
						<Brightness4Icon fontSize="small" sx={{ color: "#fff" }} />
					)}
				</IconButton>
			</Box> */}
			<AuthCard variant="outlined">
				<CardHeader
					title={MAIN_TITLE_T}
					titleTypographyProps={{
						variant: "h5",
						style: { textAlign: "center" },
					}}
				/>
				<CardContent>
					<Box
						sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
					>
						<LoginInput
							label={"Login"}
							onChange={(e) => validateLogin(e.currentTarget.value)}
							// value={state.login}
						/>
						<PasswordInput label={"Password"} onChange={validatePass} />
						{state.mode === "signup" && (
							<PasswordInput
								label={"Confirm password"}
								onChange={validateConfPass}
							/>
						)}
						{errMessage && (
							<Typography color={"error"}>{errMessage}</Typography>
						)}
					</Box>
				</CardContent>
				<CardActions style={{ justifyContent: "center" }}>
					{!NO_REGISTRATION && (
						<>
							{state.mode === "signin" ? (
								<Button
									onClick={() => setStateValue({ mode: "signup" })}
									sx={{
										color: theme.palette.text.primary,
									}}
								>
									Sign Up
								</Button>
							) : (
								<Button
									onClick={() => setStateValue({ mode: "signin" })}
									sx={{
										color: theme.palette.text.primary,
									}}
								>
									Back to Log In
								</Button>
							)}
						</>
					)}

					<Button
						variant="contained"
						onClick={onSubmit}
						color={"success"}
						sx={{ color: theme.palette.mode === "dark" ? "#000" : "#fff" }}
					>
						{state.mode === "signup" ? "Sign Up" : "Sign In"}
					</Button>
				</CardActions>
			</AuthCard>
		</AuthContainer>
	)
}

export default AuthenticationPage
