import React, { FC, useContext, useEffect, useState } from "react"
import {
	UserProfileType,
	statisticChatGPTResultType,
} from "../../../TypesComp/TypesComp"
import { MainContext } from "../../../App"
import { Box } from "@mui/material"
import { FlexBetween, ListBlockFlex } from "../../../theme/styledComponents"
import CalendarPage from "../../calendar/CalendarPage"
import StatisticChatResultElemPresent from "../presentElems/StatisticChatResultElemPresent"

interface ProfileChatGPTBlockDesktopProps {
	currentProfile: UserProfileType | undefined
}
const ProfileChatGPTBlockDesktop: FC<ProfileChatGPTBlockDesktopProps> = ({
	currentProfile,
}) => {
	const mainContext = useContext(MainContext)
	const [statisticChatGPTResult, setStatisticChatGPTResult] = useState<
		statisticChatGPTResultType | undefined
	>()

	useEffect(() => {
		setStatisticChatGPTResult({
			countMsg: Math.floor(Math.random() * 3000),
			average_number_messages_per_day: Math.floor(Math.random() * 100),
		})
	}, [currentProfile, mainContext?.selectedDate])

	return (
		<Box sx={{ width: "100%" }}>
			<FlexBetween sx={{ alignItems: "start", width: "100%" }}>
				<ListBlockFlex sx={{ width: "50%", height: window.innerHeight - 120 }}>
					<Box sx={{ maxWidth: 400 }}>
						<CalendarPage />
					</Box>
				</ListBlockFlex>
				<Box sx={{ width: "50%" }}>
					{!!statisticChatGPTResult && (
						<StatisticChatResultElemPresent
							statisticResult={statisticChatGPTResult}
						/>
					)}
				</Box>
			</FlexBetween>
		</Box>
	)
}

export default ProfileChatGPTBlockDesktop
