import React, { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import "./App.css"
import { ColorModeContext, useMode } from "./theme/theme"
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import TopBarComponent from "./components/topBar/TopBarComponent"
import SidebarComponent from "./components/sidebar/SidebarComponent"
import { Box } from "@mui/system"
import NoLoginPage from "./pages/basePages/NoLoginPage"
import AuthenticationPage from "./pages/auth/AuthenticationPage"
import { MainContextType, PeriodEventsInterface, StatisticType, UserProfileType } from "./TypesComp/TypesComp"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { NON_MOBILE } from "./CONSTANTS/constsSetting"
import { getProfileDataFromBase, getUsers } from "./API/RequestServices"
import { DateWithEventColorType } from "./pages/calendar/calendarNew/CalendarNew"

export const MainContext = React.createContext<MainContextType | null>(null)

function App() {
	const navigate = useNavigate()
	const [theme, colorMode] = useMode()
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [IsAuth, setIsAuth] = useState<boolean>(false)
	const [isShowLoginForm, setIsShowLoginForm] = useState<boolean>(false)
	const [users, setUsers] = useState<UserProfileType[]>([])
	const [currentUser, setCurrentUser] = useState<UserProfileType>()
	const isNonMobile = useMediaQuery(NON_MOBILE)
	const handleFullScreen = useFullScreenHandle()
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)
	const [statistics, setStatistic] = useState<StatisticType[]>([])

	useEffect(() => {
		if (IsAuth && currentUser?.is_staff) {
			const getUsers_ = async () => {
				await getUsers().then((response: UserProfileType[] | string) => {
					if (typeof response !== "string") {
						setUsers(response)
					} else {
						console.log(response)
					}
				})
			}
			getUsers_()
		}
	}, [IsAuth, currentUser])

	useEffect(() => {
		if (IsAuth) {
			const axiosProfile = async () => {
				return await getProfileDataFromBase()
			}
			axiosProfile()
				.then((response: UserProfileType | string) => {
					if (typeof response === "string") {
						setCurrentUser(undefined)
						setIsAuth(false)
					} else if (typeof response !== "string") {
						setCurrentUser(response)
					}
				})
				.catch((err) => {
					alert(err)
				})
		}
	}, [IsAuth])

	useEffect(() => {
		if (localStorage.getItem("Authorization")) {
			setIsAuth(true)
		} else {
			setIsAuth(false)
			setCurrentUser(undefined)
		}
	}, [])

	const logOut = () => {
		setIsAuth(false)
		localStorage.removeItem("Authorization")
		setIsOpen(false)
		navigate("/")
	}
	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<MainContext.Provider
					value={{
						currentUser,
						users,
						setUsers,
						selectedDate,
						setSelectedDate,
						statistics, 
						setStatistic
					}}
				>
					<FullScreen handle={handleFullScreen}>
						<CssBaseline />
						<Box
							sx={{
								display: "flex",
								width: "100%",
								height: "100%",
								backgroundColor: theme.palette.background.default,
								color: theme.palette.text.primary,
								overflow: "hidden",
							}}
						>
							<SidebarComponent
								setIsOpen={setIsOpen}
								isOpen={isOpen}
								isNonMobile={isNonMobile}
								isLogin={IsAuth}
								drawerWidth={isNonMobile ? "250px" : "100%"}
								logOut={logOut}
								currentUser={currentUser}
							/>
							<Box sx={{ width: "100%" }}>
								<TopBarComponent
									isOpen={isOpen}
									setIsOpen={setIsOpen}
									isLogin={IsAuth}
									isNonMobile={isNonMobile}
									setIsShowLoginForm={setIsShowLoginForm}
									handleFullScreen={handleFullScreen}
								/>
								{IsAuth ? (
									<Box>
										{/* {isNonMobile && <Outlet />}
										{!isNonMobile && !isOpen && <Outlet />} */}
										<Outlet />
									</Box>
								) : (
									<Box>
										{isShowLoginForm ? (
											<AuthenticationPage
												setIsAuth={setIsAuth}
												setCurrentUser={setCurrentUser}
											/>
										) : (
											<NoLoginPage />
										)}
									</Box>
								)}
							</Box>
						</Box>
					</FullScreen>
				</MainContext.Provider>
			</ThemeProvider>
		</ColorModeContext.Provider>
	)
}

export default App
