import React, { FC, useEffect, useState } from "react"
import { StatisticType } from "../../../TypesComp/TypesComp"
import { Box, Typography } from "@mui/material"

import {
	NO_NAME_CHAPTER_LABEL_T,
	NO_NAME_SUBJECT_LABEL_T,
} from "../../../CONSTANTS/constsTrans"
import { ListBlock } from "../../../theme/styledComponents"

interface StatisticElemBlockProps {
	filteredStatistic: StatisticType[]
}
const StatisticElemBlock: FC<StatisticElemBlockProps> = ({
	filteredStatistic,
}) => {
	const [arrSubjectsName, setArrSubjectsName] = useState<string[]>([])
	const [arrChaptersName, setArrChaptersName] = useState<string[]>([])

	useEffect(() => {
		let SetFromSubjectsName = new Set(
			filteredStatistic.map((foo) =>
				!!foo.subject_name ? foo.subject_name : NO_NAME_SUBJECT_LABEL_T
			)
		)
		setArrSubjectsName(Array.from(SetFromSubjectsName))
		let SetFromChaptersName = new Set(
			filteredStatistic.map((foo) =>
				!!foo.chapter_name ? foo.chapter_name : NO_NAME_CHAPTER_LABEL_T
			)
		)
		setArrChaptersName(Array.from(SetFromChaptersName))
	}, [filteredStatistic])
	return (
		<ListBlock sx={{ height: window.innerHeight - 120 }}>
			{arrSubjectsName.map((subName) => {
				return (
					<Box key={subName}>
						<Typography color={"text.info"}>{subName}</Typography>

						{arrChaptersName
							.filter((foo) =>
								filteredStatistic
									.filter(
										(stat) =>
											stat.subject_name === subName && stat.chapter_name === foo
									)
									.map((elem) => elem.chapter_name)
									.includes(foo)
							)
							.map((chName) => {
								return (
									<Box key={chName}>
										<Typography color={"text.link"}>{chName}</Typography>
										{filteredStatistic
											.filter(
												(stat) =>
													stat.subject_name === subName &&
													stat.chapter_name === chName
											)
											.map((statCh, i) => {
												return (
													<Box key={i}>
														<Typography>{statCh.description}</Typography>
													</Box>
												)
											})}
									</Box>
								)
							})}
					</Box>
				)
			})}
		</ListBlock>
	)
}

export default StatisticElemBlock
