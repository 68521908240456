import React, { FC } from "react"
import { statisticResultCommonStateType } from "../../../TypesComp/TypesComp"
import { Box, Stack, Typography } from "@mui/material"
import { ItemStack } from "../../../theme/styledComponents"
import {
	COUNT_CHAPTERS_LABEL_T,
	COUNT_COMPLETION_PERCENTAGE_LABEL_T,
	COUNT_FAIL_TEST_LABEL_T,
	COUNT_QUESTIONS_LABEL_T,
	COUNT_SUBJECTS_LABEL_T,
	COUNT_TRUE_TEST_LABEL_T,
} from "../../../CONSTANTS/constsTrans"

interface StatisticResultElemPresentProps {
	statisticResult: statisticResultCommonStateType
}
const StatisticResultElemPresent: FC<StatisticResultElemPresentProps> = ({
	statisticResult,
}) => {
	const getPercentage = (statisticResult: statisticResultCommonStateType) => {
		const countAllTests =
			statisticResult.countFailTests + statisticResult.countTrueTests
		const res = 100 - (statisticResult.countFailTests / countAllTests) * 100
		return res.toFixed(2)
	}
	return (
		<Stack spacing={0.3}>
			<ItemStack>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_SUBJECTS_LABEL_T}</Typography>
					<Typography ml={2}>{statisticResult.countSubjects}</Typography>
				</Box>
			</ItemStack>
			<ItemStack>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_CHAPTERS_LABEL_T}</Typography>
					<Typography ml={2}>{statisticResult.countChapters}</Typography>
				</Box>
			</ItemStack>
			<ItemStack>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_TRUE_TEST_LABEL_T}</Typography>
					<Typography ml={2} color={"text.success"}>
						{statisticResult.countTrueTests}
					</Typography>
				</Box>
			</ItemStack>
			<ItemStack>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_FAIL_TEST_LABEL_T}</Typography>
					<Typography ml={2} color={"text.error"}>
						{statisticResult.countFailTests}
					</Typography>
				</Box>
			</ItemStack>
			<ItemStack>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_QUESTIONS_LABEL_T}</Typography>
					<Typography ml={2} color={"text.info"}>
						{statisticResult.countQuestions}
					</Typography>
				</Box>
			</ItemStack>
			<ItemStack>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_COMPLETION_PERCENTAGE_LABEL_T}</Typography>
					<Typography ml={2} color={"text.info"}>
						{getPercentage(statisticResult)}%
					</Typography>
				</Box>
			</ItemStack>
		</Stack>
	)
}

export default StatisticResultElemPresent
