import {
	Box,
	IconButton,
	// ImageList,
	// ImageListItem,
	// ImageListItemBar,
	Modal,
} from "@mui/material"
import React, { FC, useState } from "react"
import { FlexBetween, ModalInner } from "../../../theme/styledComponents"
import CropOutlinedIcon from "@mui/icons-material/CropOutlined"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"

interface ModalShowingImageProps {
	image: string
	open: boolean
	closeImage(): void
}
const ModalShowingImage: FC<ModalShowingImageProps> = ({
	image,
	closeImage,
	open,
}) => {
	const widthImg = (window.innerWidth / 100) * 90
	const heightImg = (window.innerHeight / 100) * 90
	const [isZooming, setIsZooming] = useState<boolean>(false)

	const zoomImage = (mode: boolean) => {
		setIsZooming(mode)
	}
	// const widthImg = window.innerWidth
	// const heightImg = window.innerHeight
	return (
		<Modal
			open={open}
			onClose={closeImage}
			aria-labelledby="chapter-modal-form"
			aria-describedby="chapter-modal-description"
			container={() => document.getElementsByClassName("fullscreen")[0]}
		>
			<ModalInner
				sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
			>
				{/* <ImageList>
					<ImageListItem>
						<img
							// srcSet={`${image}?w=${heightImg}&h=${widthImg}&fit=crop&auto=format`}
							src={`${image}?w=${heightImg}&h=${widthImg}&fit=crop&auto=format`}
							// srcSet={`${currentQuestion?.image}`}
							// src={`${currentQuestion?.image}`}
							alt={"no img"}
							loading="lazy"
						/>
						<ImageListItemBar
							actionIcon={
								<IconButton
									// sx={{ color: "white" }}
									aria-label={`close `}
									onClick={closeImage}
								>
									<CropOutlinedIcon />
								</IconButton>
							}
							position="top"
							actionPosition="left"
						/>
					</ImageListItem>
				</ImageList> */}
				<FlexBetween>
					<IconButton
						// sx={{ color: "white" }}
						aria-label={`close `}
						onClick={closeImage}
					>
						<CropOutlinedIcon />
					</IconButton>
					<IconButton
						// sx={{ color: "white" }}
						aria-label={`close `}
						onClick={() => zoomImage(!isZooming)}
					>
						{isZooming ? <ZoomOutIcon /> : <ZoomInIcon />}
					</IconButton>
				</FlexBetween>

				{isZooming ? (
					<Box sx={{ height: heightImg, width: widthImg, overflow: "auto" }}>
						<img
							src={`${image}`}
							alt={"no img"}
							loading="lazy"
							// height={heightImg > widthImg ? "" : heightImg}
							// width={widthImg > heightImg ? "" : widthImg}
						/>
					</Box>
				) : (
					<Box sx={{ height: heightImg }}>
						<img
							src={`${image}?w=${heightImg}&h=${widthImg}&fit=crop&auto=format`}
							alt={"no img"}
							loading="lazy"
							height={heightImg > widthImg ? "" : heightImg}
							width={widthImg > heightImg ? "" : widthImg}
						/>
					</Box>
				)}
			</ModalInner>
		</Modal>
	)
}

export default ModalShowingImage
