import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { ChapterType } from "../../../../TypesComp/TypesComp"
import { Box, Button, ButtonGroup, Modal } from "@mui/material"
import TextInput from "../../../../components/inputs/TextInput"
import {
	CANCEL_T,
	CHAPTER_NAME_T,
	SAVE_T,
	SUBMIT_T,
} from "../../../../CONSTANTS/constsTrans"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"

interface ChapterFormProps {
	chapter: ChapterType | undefined
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	addChapter(ch: ChapterType): void
}
const ChapterForm: FC<ChapterFormProps> = ({
	chapter,
	open,
	setOpen,
	addChapter,
}) => {
	const [name, setName] = useState<string>(!!chapter ? chapter.name : "")
	const isLoading = false
	// const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		console.log(chapter)
	}, [chapter])

	const validateName = (value: string) => {
		setName(value)
	}

	const cancel = () => {
		setOpen(false)
	}

	const onSubmit = () => {
		addChapter({
			id: chapter?.id,
			name: name,
			is_active: !!chapter ? chapter.is_active : true,
			subject: !!chapter ? chapter.subject : NaN,
		})
	}
	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="chapter-modal-form"
			aria-describedby="chapter-modal-description"
		>
			<Box sx={style}>
				<TextInput
					label={CHAPTER_NAME_T}
					value={name}
					onChange={validateName}
				/>
				<ButtonGroup variant="outlined" aria-label="loading button group">
					<Button onClick={cancel}>{CANCEL_T}</Button>
					<LoadingButton
						loading={isLoading}
						loadingPosition="start"
						startIcon={<SaveIcon />}
						onClick={onSubmit}
					>
						{isLoading ? SAVE_T : SUBMIT_T}
					</LoadingButton>
				</ButtonGroup>
			</Box>
		</Modal>
	)
}

const style = {
	position: "absolute" as "absolute",
	display: "flex",
	flexDirection: "column",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default ChapterForm
