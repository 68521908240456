import { Button, ButtonGroup, Chip, IconButton } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { TODAY_T, YESTERDAY_T } from "../../../CONSTANTS/constsTrans"
import { FlexBetween } from "../../../theme/styledComponents"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { LOCALE_DATE_PARAM_STRING_RU } from "../../../CONSTANTS/constsSetting"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

interface DataChangerBlockProps {
	currentDate: Date
	changeDate(date: Date): void
}
const DataChangerBlock: FC<DataChangerBlockProps> = ({
	currentDate,
	changeDate,
}) => {
	const [isToday, setIsToday] = useState<boolean>(true)
	useEffect(() => {
		setIsToday(
			currentDate.toLocaleDateString([LOCALE_DATE_PARAM_STRING_RU]) ===
				new Date().toLocaleDateString([LOCALE_DATE_PARAM_STRING_RU])
		)
	}, [currentDate])

	const changeDate_ = (param: 1 | -1 | 0 | -2) => {
		const dayMilliseconds = 24 * 60 * 60 * 1000
		if (param === -1) {
			changeDate(
				new Date(currentDate.setTime(currentDate.getTime() - dayMilliseconds))
			)
		} else if (param === 1) {
			!isToday &&
				changeDate(
					new Date(currentDate.setTime(currentDate.getTime() + dayMilliseconds))
				)
		} else if (param === 0) {
			changeDate(new Date())
		} else if (param === -2) {
			changeDate(new Date(new Date().getTime() - dayMilliseconds))
		}
	}
	return (
		<FlexBetween p={1}>
			<ButtonGroup variant="text" aria-label="Basic button group">
				<IconButton onClick={() => changeDate_(-1)}>
					<ArrowBackIosIcon />
				</IconButton>
				<Button onClick={() => changeDate_(-2)}>{YESTERDAY_T}</Button>
				<Button onClick={() => changeDate_(0)}>{TODAY_T}</Button>
				<IconButton onClick={() => changeDate_(1)} disabled={isToday}>
					<ArrowForwardIosIcon />
				</IconButton>
			</ButtonGroup>
			<Chip
				variant="outlined"
				label={currentDate.toLocaleDateString([LOCALE_DATE_PARAM_STRING_RU])}
			/>
		</FlexBetween>
	)
}

export default DataChangerBlock
