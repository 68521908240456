import { Box, IconButton, Typography } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { ListBlockFlex } from "../../theme/styledComponents"
import TextInput from "../../components/inputs/TextInput"
import { INTER_MSG_TEXT_LABEL_T } from "../../CONSTANTS/constsTrans"
import { sendChatMsg } from "../../API/RequestServices"
import { BotAnswerType } from "../../TypesComp/TypesComp"
import SendIcon from "@mui/icons-material/Send"
import { LoadingButton } from "@mui/lab"

interface ChatWrapperProps {}
const ChatWrapper: FC<ChatWrapperProps> = () => {
	const [currentMsg, setCurrentMsg] = useState<string>("")
	const [msgUserList, setMsgUserList] = useState<string[]>([])
	const [msgBotList, setMsgBotList] = useState<BotAnswerType[]>([])
	const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false)
	// const [errorMsg, setErrorMsg] = useState<string|undefined>(undefined)

	useEffect(() => {
		console.log(msgUserList)
	}, [msgUserList])
	useEffect(() => {
		console.log(msgBotList)
	}, [msgBotList])

	const sendMsg = async () => {
		if (!!currentMsg) {
			setMsgUserList((prev) => [...prev, currentMsg])
			const data = { msg: currentMsg }
			setIsBtnDisabled(true)
			await sendChatMsg(data).then((response: BotAnswerType | string) => {
				if (typeof response !== "string") {
					setMsgBotList((prev) => [...prev, response])
					setCurrentMsg("")
					setIsBtnDisabled(false)
				} else {
					// console.error("status => ", response)
					setMsgBotList((prev) => [
						...prev,
						{
							text: "Вопрос выплавил предохранители боту, и он не смог на него ответить. Скорее всего он просто состарился.",
						},
					])
					setCurrentMsg("")
					setIsBtnDisabled(false)
				}
			})
		}
	}
	const validateText = (text: string) => {
		setCurrentMsg(text)
	}
	return (
		<Box>
			<ListBlockFlex>
				{msgUserList.map((msg, i) => {
					return (
						<Box key={i}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "start",
								}}
								pr={15}
							>
								<Typography color={"text.info"}>{msg}</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "end",
								}}
								pl={15}
							>
								{msgBotList.length >= i && (
									<Typography>{msgBotList[i]?.text}</Typography>
								)}
							</Box>
						</Box>
					)
				})}
			</ListBlockFlex>
			<Box p={1}>
				<TextInput
					label={INTER_MSG_TEXT_LABEL_T}
					value={currentMsg}
					onChange={validateText}
					// onPaste={handlePaste}
					props={{ multiline: true, fullWidth: true }}
				/>
				<LoadingButton
					onClick={sendMsg}
					loading={isBtnDisabled}
					loadingPosition="start"
					startIcon={<SendIcon />}
					variant="outlined"
					sx={{ backgroundColor: "none" }}
				>
					Send
				</LoadingButton>
			</Box>
		</Box>
	)
}

export default ChatWrapper
