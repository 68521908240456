import {
	Box,
	Button,
	Modal,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material"
import React, { FC, useState } from "react"
import { UserAddFormType } from "../../TypesComp/TypesComp"
import {
	NAME_VALID_ERROR_TITLE_T,
	PASS1_TITLE_T,
	PASS1_VALID_ERROR_TITLE_T,
	PASS_TITLE_T,
	PASS_VALID_ERROR_TITLE_T,
	SUBMIT_T,
	USERNAME_TITLE_T,
} from "../../CONSTANTS/constsTrans"
import TextInput from "../../components/inputs/TextInput"
import PasswordInput from "../../components/inputs/PasswordInput"
import { NON_MOBILE } from "../../CONSTANTS/constsSetting"

interface ModalAddFormProps {
	open: boolean
	closeModal(submitData: UserAddFormType | undefined): void
}
const ModalAddForm: FC<ModalAddFormProps> = ({ open, closeModal }) => {
	const theme = useTheme()
	const isNonMobile = useMediaQuery(NON_MOBILE)
	const [submitData, setSubmitData] = useState({
		name: "",
		pass: "",
		pass1: "",
	})
	const [errorText, setErrorText] = useState<string>()

	const setStateUserAddFormValue = (val: Partial<UserAddFormType>) => {
		submitData && setSubmitData({ ...submitData, ...val })
	}
	const validateName = (name: string) => {
		setStateUserAddFormValue({ name })
	}
	const validatePass = (pass: string) => {
		setStateUserAddFormValue({ pass })
	}
	const validatePass1 = (pass1: string) => {
		setStateUserAddFormValue({ pass1 })
	}

	const finalValid = () => {
		if (submitData.name.length < 5) {
			setErrorText(NAME_VALID_ERROR_TITLE_T)
			return
		}
		if (submitData.pass.length < 8) {
			setErrorText(PASS_VALID_ERROR_TITLE_T)
			return
		}
		if (submitData.pass !== submitData.pass1) {
			setErrorText(PASS1_VALID_ERROR_TITLE_T)
			return
		}
		closeModal(submitData)
	}
	const cancel = () => {
		closeModal(undefined)
	}

	return (
		<Modal
			open={open}
			onClose={cancel}
			aria-labelledby="chapter-modal-form"
			aria-describedby="chapter-modal-description"
		>
			<Box sx={{ ...style, width: isNonMobile ? "50%" : "100%" }}>
				<TextInput
					label={USERNAME_TITLE_T}
					value={submitData.name}
					onChange={validateName}
					props={{ multiline: true }}
				/>

				<PasswordInput label={PASS_TITLE_T} onChange={validatePass} />

				<PasswordInput label={PASS1_TITLE_T} onChange={validatePass1} />

				<Typography color={"error"}>{errorText}</Typography>

				<Button
					onClick={finalValid}
					sx={{
						color: theme.palette.text.primary,
					}}
				>
					{SUBMIT_T}
				</Button>
			</Box>
		</Modal>
	)
}

const style = {
	position: "absolute" as "absolute",
	display: "flex",
	flexDirection: "column",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// width: "50%",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default ModalAddForm
