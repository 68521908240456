import React, { Dispatch, SetStateAction } from "react"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import {
	Box,
	Button,
	ButtonGroup,
	IconButton,
	styled,
	Typography,
} from "@mui/material"
import TodayIcon from "@mui/icons-material/Today"

const ButtonStyled = styled(Button)(({ theme }) => ({
	color: theme.palette.text.primary,
	// width: "40%",
	// marginRight: "40px",
}))
interface NavigateBlockProps {
	selectedMonth: number
	selectedYear: number
	setSelectedMonth: Dispatch<SetStateAction<number>>
	setSelectedYear: Dispatch<SetStateAction<number>>
	setIsSelectMod: Dispatch<SetStateAction<boolean>>
	languageDesignation: string
	setToday(): void
}
const NavigateBlock: React.FC<NavigateBlockProps> = ({
	selectedMonth,
	setSelectedMonth,
	setSelectedYear,
	selectedYear,
	setIsSelectMod,
	languageDesignation,
	setToday,
}) => {
	const changeMonth = (delta: number) => {
		if (selectedMonth + delta === -1) {
			setSelectedMonth(11)
			setSelectedYear((prev) => prev - 1)
		} else if (selectedMonth + delta === 12) {
			setSelectedMonth(0)
			setSelectedYear((prev) => prev + 1)
		} else {
			setSelectedMonth((prev) => prev + delta)
		}
	}

	return (
		<Box
			sx={{ display: "flex", width: "100%", justifyContent: "space-around" }}
		>
			{/* <ButtonGroup variant="text" aria-label="text button group" fullWidth> */}
			<IconButton onClick={() => changeMonth(-1)}>
				<ArrowBackIosIcon />
			</IconButton>

			<ButtonStyled onClick={() => setIsSelectMod(true)}>
				<Typography variant="body2">
					{new Date(selectedYear, selectedMonth, 1).toLocaleString(
						languageDesignation,
						{ month: "long", year: "numeric" }
					)}
				</Typography>
			</ButtonStyled>

			<IconButton onClick={() => changeMonth(1)}>
				<ArrowForwardIosIcon />
			</IconButton>

			<IconButton onClick={setToday}>
				<TodayIcon />
			</IconButton>
			{/* </ButtonGroup> */}
		</Box>
	)
}

export default NavigateBlock
