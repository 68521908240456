import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import s from "../CalendarNew.module.css";

interface SelectedYearProps {
    selectedYear: number
    setSelectedYear: Dispatch<SetStateAction<number>>
}

const SelectedYear: React.FC<SelectedYearProps> = ({selectedYear, setSelectedYear}) => {
    const [yearList, setYearList] = useState<Array<number>>()
    const myRef = useRef<undefined | HTMLDivElement>(undefined)

    useEffect(() => {
        let year_list: Array<number> = []
        let delta = 30
        let startYear = new Date().getFullYear()
        for (let i = startYear - delta; i < startYear + delta; i++) {
            year_list.push(i)
        }
        setYearList([...year_list])
    }, [])
    useEffect(() => {
        if (!!myRef.current) {
            myRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }, [myRef, yearList, selectedYear])

    return (
        <div className={s.yearBlock} >
            {yearList?.map((elem, index) => {
                return (
                    <div key={index} onClick={() => setSelectedYear(elem)}>
                        {selectedYear === elem ?
                            // @ts-ignore
                            <div ref={myRef} className={s.yearElemSelected}>
                                {elem}
                            </div>
                            :
                            <div className={s.yearElem}>
                                {elem}
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    );
};

export default SelectedYear;