import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	styled,
} from "@mui/material"
import React from "react"

interface PasswordInputProps {
	label?: string
	onChange?: (e: any) => void
}
interface State {
	password: string
	showPassword: boolean
}

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: theme.palette.text.primary + "!important",
}))

const StyledPasswordInput = styled(OutlinedInput)(({ theme }) => ({
	boxShadow:
		theme.palette.mode === "dark"
			? "0 0 0 100px #2f4352 inset"
			: "0 0 0 100px #fff inset",
	".MuiOutlinedInput-input:-webkit-autofill": {
		WebkitBoxShadow:
			theme.palette.mode === "dark"
				? "0 0 0 100px #2f4352 inset"
				: "0 0 0 100px #fff inset",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: theme.palette.text.primary,
	},
}))

const PasswordInput: React.FC<PasswordInputProps> = ({ label, onChange }) => {
	const [values, setValues] = React.useState<State>({
		password: "",
		showPassword: false,
	})
	const handleChange =
		(prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value })
			onChange && onChange(event.target.value)
		}

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		})
	}

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault()
	}
	return (
		<FormControl variant="outlined" margin="dense">
			<StyledInputLabel>{label}</StyledInputLabel>
			<StyledPasswordInput
				type={values.showPassword ? "text" : "password"}
				value={values.password}
				onChange={handleChange("password")}
				style={{ display: "flex" }}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{values.showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				label="Password"
			/>
		</FormControl>
	)
}

export default PasswordInput
