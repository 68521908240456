export const MAIN_TITLE = "GBS Tools"
export const PART_ONE_TITLE = "Главная"
export const PART_TWO_TITLE = "Тесты"
export const TESTS_TITLE = "Тесты"
export const PART_THREE_TITLE = "Чат GPT"
export const PART_FOUR_TITLE = "Настройки"
export const LOGOUT_TITLE = "Выйти"
export const LOGIN_TITLE = "Войти"
export const INTER_DATA_TITLE = "Ознакомление"
export const WALKTHROUGH_TITLE = "Прохождение"
export const SELECT_SUBJECT = "Выберите дисциплину"
export const SELECT_CHAPTER = "Выберите Раздел"
export const SELECT_FROM_ALL = "----- по всем -----"
export const CHAPTERS = "Темы"
export const QUESTIONS = "Вопросы"
export const ANSWERS = "Ответы"
export const CHAPTER_NAME = "Раздел"
export const CANCEL = "Отмена"
export const SUBMIT = "Записать"
export const SAVE = "Запись"
export const QUESTION_NAME = "Вопрос"
export const ANSWER_NAME = "Ответ"
export const IS_MISSING_ANSWER = "Есть пропущенные ответы"
export const QUESTION_EXIST_TITLE = "Такая запись существует."
export const START_TITLE = "Старт"
export const FINISH_TITLE = "Завершить"
export const QUITE_TITLE = "Выйти"
export const START_AGAIN_TITLE = "Повторить"
export const START_NEW_TITLE = "Начать новый"
export const CONTINUM_TITLE = "Продолжить"
export const CONFIRM_TITLE = "Подтвердить"
export const NO_ANSWER = "Не выбран ответ"
export const TRUE_ANSWER = "Ответ правильный"
export const MISS_ANSWER = "Ответ совсем не верный Правильный ответ: "
export const ADD_USER = "Добавить пользователя"
export const ADMINS_PART = "Админка"
export const NAME_VALID_ERROR_TITLE = "Имя должно содержать 8 символов и больше"
export const PASS_VALID_ERROR_TITLE =
	"Пароль должен содержать 8 символов и больше"
export const PASS1_VALID_ERROR_TITLE = "Пароли не совпадают"
export const PASS_TITLE = "Пароль"
export const PASS1_TITLE = "Подтверждение пароля"
export const USERNAME_TITLE = "Логин"
export const TEST_FAILED = "Тест не сдан"
export const TEST_PASSED = "Тест сдан"
export const TODAY = "Сегодня"
export const YESTERDAY = "Вчера"

// Тесты
export const TITLE_BTN_CLEAR_TEST_LIST = "Очистить КЭШ."
export const TITLE_MAIN_TEST = "Удачного прохождения тестов!"
export const TITLE_SHOW_STATISTIC = "Показать статистику тестов"
export const COUNT_SUBJECTS_LABEL = "Количество разделов"
export const COUNT_CHAPTERS_LABEL = "Количество Тем"
export const COUNT_TRUE_TEST_LABEL = "Количество пройденных тестов"
export const COUNT_FAIL_TEST_LABEL = "Количество проваленных тестов"
export const COUNT_QUESTIONS_LABEL = "Количество пройденных вопросов ответов"
export const COUNT_COMPLETION_PERCENTAGE_LABEL = "Процент прохождения"

export const NO_NAME_SUBJECT_LABEL = "Имя раздела неопределенно"
export const NO_NAME_CHAPTER_LABEL = "Имя темы неопределенно"

// gpt
export const USER_GPT_LABEL = "Пользователь ChatGPT"
export const COUNT_MSG_LABEL = "Количество сообщений"
export const COUNT_AVERAGE_MSG_LABEL = "Среднее количество сообщений в день"
export const INTER_MSG_TEXT_LABEL = "Введите текст сообщения"