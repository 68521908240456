import { Box, Paper, Stack, styled } from "@mui/material"

export const ListBlock = styled(Stack)({
	height: window.innerHeight - 190,
	padding: "3px",
	overflowY: "auto",
	// cursor: "pointer",
})

export const ItemStack = styled(Paper)(({ theme }) => ({
	// backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	backgroundColor: theme.palette.background.default,
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "left",
	color: theme.palette.text.primary,
	cursor: "pointer",
}))

export const ListBlockFlex = styled(Stack)({
	display: "flex",
	flexDirection: "column",
	height: window.innerHeight - 220,
	padding: "3px",
	overflowY: "auto",
	// cursor: "pointer",
})

export const FlexBetween = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
})
export const FlexEvenly = styled(Box)({
	display: "flex",
	justifyContent: "space-evenly",
	alignItems: "center",
})
export const FlexCenter = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
})

export const ModalInner = styled(Box)(({ theme }) => ({
	position: "absolute" as "absolute",
	display: "flex",
	flexDirection: "column",
	top: "50%",
	left: "50%",
	width: "95%",
	height: "95%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: "24",
	p: 4,
}))
