import { FormControl, styled, TextField, useTheme } from "@mui/material"
import React from "react"

const DisabledTextField = styled(TextField)(({ theme }) => ({
	width: "100%",

	"& input:valid + fieldset": {
		borderWidth: 2,
	},
	"& input:valid:focus + fieldset": {
		borderLeftWidth: 6,
		padding: "4px !important", // override inline-style
	},
	"& input:disabled + fieldset": {
		borderWidth: 2,
	},
	"& input:disabled:hover + fieldset": {
		borderLeftWidth: 6,
		padding: "4px !important", // override inline-style
	},
	"& input:invalid + fieldset": {
		borderWidth: 2,
	},
	// "& input": {
	// 	padding: "10px 0",
	// },
	// "& input + fieldset": {
	// 	padding: "10px 0",
	// },
	"& .MuiOutlinedInput-root": {
		// "& fieldset": {
		// 	borderColor: "red",
		// },
		"&:hover fieldset": {
			borderColor: theme.palette.text,
		},
		"&.Mui-focused fieldset": {
			borderColor: theme.palette.text.primary,
		},
	},
	"& label.Mui-focused": {
		color: theme.palette.text.primary,
	},
}))
interface TextInputProps {
	label?: string
	value?: string
	onChange?: (value: string) => void
	onPaste?: (value: string) => void
	type?: string
	props?: any
	validColor?: string
	invalidColor?: string
	borderLeftWidth?: number
	borderRightWidth?: number
}

const TextInput: React.FC<TextInputProps> = ({
	validColor,
	borderLeftWidth,
	borderRightWidth,
	invalidColor,
	props,
	label,
	value,
	type,
	onChange,
	onPaste
}) => {
	const theme = useTheme()
	// const DisabledTextField = styled(TextField)({
	// 	"& input:valid + fieldset": {
	// 		borderColor: validColor ? validColor : "darkgray",
	// 		borderWidth: 2,
	// 	},
	// 	"& input:valid:focus + fieldset": {
	// 		borderColor: validColor ? validColor : "darkgray",
	// 		borderLeftWidth: 6,
	// 		padding: "4px !important", // override inline-style
	// 	},
	// 	"& input:disabled + fieldset": {
	// 		borderColor: validColor ? validColor : "darkgray",
	// 		borderWidth: 2,
	// 		borderLeftWidth: borderLeftWidth ? borderLeftWidth : 2,
	// 		borderRightWidth: borderRightWidth ? borderRightWidth : 2,
	// 	},
	// 	"& input:disabled:hover + fieldset": {
	// 		borderColor: validColor ? validColor : "darkgray",
	// 		borderLeftWidth: 6,
	// 		padding: "4px !important", // override inline-style
	// 	},
	// 	"& input:invalid + fieldset": {
	// 		borderColor: invalidColor ? invalidColor : "darkred",
	// 		borderWidth: 2,
	// 	},
	// })
	return (
		<FormControl
			variant="outlined"
			fullWidth={props?.fullWidth ? props?.fullWidth : false}
		>
			<DisabledTextField
				sx={
					validColor
						? {
								"& input:valid + fieldset": {
									borderColor: validColor ? validColor : "darkgray",
								},
								"& input:valid:focus + fieldset": {
									borderColor: validColor ? validColor : "darkgray",
								},
								"& input:disabled": {
									WebkitTextFillColor: theme.palette.text.primary,
								},
								"& input:disabled + fieldset": {
									borderColor: validColor
										? validColor + "!important"
										: "darkgray",
									borderLeftWidth: borderLeftWidth ? borderLeftWidth : 2,
									borderRightWidth: borderRightWidth ? borderRightWidth : 2,
								},
								"& input:disabled:hover + fieldset": {
									borderColor: validColor ? validColor : "darkgray",
								},
						  }
						: invalidColor
						? {
								"& input:invalid + fieldset": {
									borderColor: invalidColor ? invalidColor : "darkred",
								},
						  }
						: {}
				}
				label={label}
				variant="outlined"
				margin="dense"
				value={value}
				type={type}
				// inputProps={props}
				disabled={!!props?.disabled}
				multiline={props?.multiline ? props.multiline : false}
				maxRows={props?.maxRows ? props.maxRows : undefined}
				onChange={onChange && ((e: any) => onChange(e.target.value))}
				onPaste={onPaste && ((e: any) => onPaste(e.clipboardData.getData('Text')))}
			/>
			{/* {props?.disabled ? (
				<DisabledTextField
					sx={{
						"& input:valid + fieldset": {
							borderColor: validColor ? validColor : "darkgray",
						},
						"& input:valid:focus + fieldset": {
							borderColor: validColor ? validColor : "darkgray",
						},
						"& input:disabled + fieldset": {
							borderColor: validColor ? validColor : "darkgray",
							// borderLeftWidth: borderLeftWidth ? borderLeftWidth : 2,
							// borderRightWidth: borderRightWidth ? borderRightWidth : 2,
						},
						"& input:disabled:hover + fieldset": {
							borderColor: validColor ? validColor : "darkgray",
						},
						"& input:invalid + fieldset": {
							borderColor: invalidColor ? invalidColor : "darkred",
						},
					}}
					label={label}
					variant="outlined"
					margin="dense"
					value={value}
					type={type}
					inputProps={props}
					onChange={onChange && ((e: any) => onChange(e.target.value))}
				/>
			) : (
				<TextField
					label={label}
					variant="outlined"
					margin="dense"
					value={value}
					type={type}
					inputProps={props}
					onChange={onChange && ((e: any) => onChange(e.target.value))}
				/>
			)} */}
		</FormControl>
	)
}

export default TextInput
