import { getTrans } from "../utils/translate"
import {
	LOGIN_TITLE,
	LOGOUT_TITLE,
	MAIN_TITLE,
	PART_FOUR_TITLE,
	PART_ONE_TITLE,
	PART_THREE_TITLE,
	PART_TWO_TITLE,
	INTER_DATA_TITLE,
	WALKTHROUGH_TITLE,
	SELECT_SUBJECT,
	CHAPTERS,
	QUESTIONS,
	ANSWERS,
	CHAPTER_NAME,
	CANCEL,
	SUBMIT,
	SAVE,
	QUESTION_NAME,
	ANSWER_NAME,
	QUESTION_EXIST_TITLE,
	SELECT_CHAPTER,
	START_TITLE,
	FINISH_TITLE,
	START_AGAIN_TITLE,
	START_NEW_TITLE,
	QUITE_TITLE,
	CONTINUM_TITLE,
	SELECT_FROM_ALL,
	CONFIRM_TITLE,
	NO_ANSWER,
	TRUE_ANSWER,
	MISS_ANSWER,
	ADD_USER,
	ADMINS_PART,
	NAME_VALID_ERROR_TITLE,
	PASS_VALID_ERROR_TITLE,
	PASS1_VALID_ERROR_TITLE,
	PASS_TITLE,
	PASS1_TITLE,
	USERNAME_TITLE,
	TEST_FAILED,
	TEST_PASSED,
	TESTS_TITLE,
	TODAY,
	YESTERDAY,
	COUNT_SUBJECTS_LABEL,
	COUNT_CHAPTERS_LABEL,
	COUNT_TRUE_TEST_LABEL,
	COUNT_FAIL_TEST_LABEL,
	COUNT_QUESTIONS_LABEL,
	COUNT_COMPLETION_PERCENTAGE_LABEL,
    NO_NAME_SUBJECT_LABEL,
    NO_NAME_CHAPTER_LABEL,
    USER_GPT_LABEL,
    COUNT_MSG_LABEL,
    COUNT_AVERAGE_MSG_LABEL,
    INTER_MSG_TEXT_LABEL,
	TITLE_SHOW_STATISTIC,
	TITLE_MAIN_TEST,
	TITLE_BTN_CLEAR_TEST_LIST,
	IS_MISSING_ANSWER,
} from "./consts"

export const MAIN_TITLE_T = getTrans(`ln.${MAIN_TITLE}`)
export const PART_ONE_TITLE_T = getTrans(`ln.${PART_ONE_TITLE}`)
export const PART_TWO_TITLE_T = getTrans(`ln.${PART_TWO_TITLE}`)
export const PART_THREE_TITLE_T = getTrans(`ln.${PART_THREE_TITLE}`)
export const PART_FOUR_TITLE_T = getTrans(`ln.${PART_FOUR_TITLE}`)
export const LOGOUT_TITLE_T = getTrans(`ln.${LOGOUT_TITLE}`)
export const LOGIN_TITLE_T = getTrans(`ln.${LOGIN_TITLE}`)
export const USERNAME_TITLE_T = getTrans(`ln.${USERNAME_TITLE}`)
export const PASS_TITLE_T = getTrans(`ln.${PASS_TITLE}`)
export const PASS1_TITLE_T = getTrans(`ln.${PASS1_TITLE}`)
export const INTER_DATA_TITLE_T = getTrans(`ln.${INTER_DATA_TITLE}`)
export const WALKTHROUGH_TITLE_T = getTrans(`ln.${WALKTHROUGH_TITLE}`)
export const SELECT_SUBJECT_T = getTrans(`ln.${SELECT_SUBJECT}`)
export const SELECT_CHAPTER_T = getTrans(`ln.${SELECT_CHAPTER}`)
export const CHAPTERS_T = getTrans(`ln.${CHAPTERS}`)
export const QUESTIONS_T = getTrans(`ln.${QUESTIONS}`)
export const ANSWERS_T = getTrans(`ln.${ANSWERS}`)
export const CHAPTER_NAME_T = getTrans(`ln.${CHAPTER_NAME}`)
export const CANCEL_T = getTrans(`ln.${CANCEL}`)
export const SUBMIT_T = getTrans(`ln.${SUBMIT}`)
export const SAVE_T = getTrans(`ln.${SAVE}`)
export const QUESTION_NAME_T = getTrans(`ln.${QUESTION_NAME}`)
export const ANSWER_NAME_T = getTrans(`ln.${ANSWER_NAME}`)
export const QUESTION_EXIST_TITLE_T = getTrans(`ln.${QUESTION_EXIST_TITLE}`)
export const START_TITLE_T = getTrans(`ln.${START_TITLE}`)
export const FINISH_TITLE_T = getTrans(`ln.${FINISH_TITLE}`)
export const START_AGAIN_TITLE_T = getTrans(`ln.${START_AGAIN_TITLE}`)
export const START_NEW_TITLE_T = getTrans(`ln.${START_NEW_TITLE}`)
export const QUITE_TITLE_T = getTrans(`ln.${QUITE_TITLE}`)
export const CONTINUM_TITLE_T = getTrans(`ln.${CONTINUM_TITLE}`)
export const SELECT_FROM_ALL_T = getTrans(`ln.${SELECT_FROM_ALL}`)
export const CONFIRM_TITLE_T = getTrans(`ln.${CONFIRM_TITLE}`)
export const NO_ANSWER_T = getTrans(`ln.${NO_ANSWER}`)
export const TRUE_ANSWER_T = getTrans(`ln.${TRUE_ANSWER}`)
export const MISS_ANSWER_T = getTrans(`ln.${MISS_ANSWER}`)
export const ADD_USER_T = getTrans(`ln.${ADD_USER}`)
export const ADMINS_PART_T = getTrans(`ln.${ADMINS_PART}`)
export const NAME_VALID_ERROR_TITLE_T = getTrans(`ln.${NAME_VALID_ERROR_TITLE}`)
export const PASS_VALID_ERROR_TITLE_T = getTrans(`ln.${PASS_VALID_ERROR_TITLE}`)
export const PASS1_VALID_ERROR_TITLE_T = getTrans(
	`ln.${PASS1_VALID_ERROR_TITLE}`
)
export const TEST_FAILED_T = getTrans(`ln.${TEST_FAILED}`)
export const TEST_PASSED_T = getTrans(`ln.${TEST_PASSED}`)
export const TESTS_TITLE_T = getTrans(`ln.${TESTS_TITLE}`)
export const TODAY_T = getTrans(`ln.${TODAY}`)
export const YESTERDAY_T = getTrans(`ln.${YESTERDAY}`)

// Результаты тестов
export const TITLE_IS_MISSING_ANSWER_T = getTrans(`ln.${IS_MISSING_ANSWER}`)
export const TITLE_BTN_CLEAR_TEST_LIST_T = getTrans(`ln.${TITLE_BTN_CLEAR_TEST_LIST}`)
export const TITLE_MAIN_TEST_T = getTrans(`ln.${TITLE_MAIN_TEST}`)
export const TITLE_SHOW_STATISTIC_T = getTrans(`ln.${TITLE_SHOW_STATISTIC}`)
export const COUNT_SUBJECTS_LABEL_T = getTrans(`ln.${COUNT_SUBJECTS_LABEL}`)
export const COUNT_CHAPTERS_LABEL_T = getTrans(`ln.${COUNT_CHAPTERS_LABEL}`)
export const COUNT_TRUE_TEST_LABEL_T = getTrans(`ln.${COUNT_TRUE_TEST_LABEL}`)
export const COUNT_FAIL_TEST_LABEL_T = getTrans(`ln.${COUNT_FAIL_TEST_LABEL}`)
export const COUNT_QUESTIONS_LABEL_T = getTrans(`ln.${COUNT_QUESTIONS_LABEL}`)
export const COUNT_COMPLETION_PERCENTAGE_LABEL_T = getTrans(
	`ln.${COUNT_COMPLETION_PERCENTAGE_LABEL}`
)

export const NO_NAME_SUBJECT_LABEL_T = getTrans(`ln.${NO_NAME_SUBJECT_LABEL}`)
export const NO_NAME_CHAPTER_LABEL_T = getTrans(`ln.${NO_NAME_CHAPTER_LABEL}`)


// gpt
export const USER_GPT_LABEL_T = getTrans(`ln.${USER_GPT_LABEL}`)
export const COUNT_MSG_LABEL_T = getTrans(`ln.${COUNT_MSG_LABEL}`)
export const COUNT_AVERAGE_MSG_LABEL_T = getTrans(`ln.${COUNT_AVERAGE_MSG_LABEL}`)
export const INTER_MSG_TEXT_LABEL_T = getTrans(`ln.${INTER_MSG_TEXT_LABEL}`)
