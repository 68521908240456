import { Box, Button, ButtonGroup } from "@mui/material"
import React, { FC, useContext, useState } from "react"
import { FlexBetween } from "../../../theme/styledComponents"
import { ADD_USER_T, TESTS_TITLE_T } from "../../../CONSTANTS/constsTrans"
import { MainContext } from "../../../App"
import ProfileTestBlockDesktop from "./ProfileTestBlockDesktop"
import { UserAddFormType, UserProfileType } from "../../../TypesComp/TypesComp"
import ModalAddForm from "../../adminsTools/ModalAddForm"
import ProfilesListDesktop from "./ProfilesListDesktop"
import ModalUpdateFormUser from "../ModalUpdateFormUser"
import ProfileChatGPTBlockDesktop from "./ProfileChatGPTBlockDesktop"

interface ProfilesWrapperDesktopProps {
	showAddUserForm(): void
	switchMode(mode: "test" | "chatGPT"): void
	mode: "test" | "chatGPT"
	handleSelectUser(user: UserProfileType): void
	updateUser(user: UserProfileType): void
	currentProfile: UserProfileType | undefined
	isOpen: boolean
	closeModal(ob: UserAddFormType | undefined): void
}
const ProfilesWrapperDesktop: FC<ProfilesWrapperDesktopProps> = ({
	showAddUserForm,
	switchMode,
	mode,
	handleSelectUser,
	currentProfile,
	isOpen,
	closeModal,
	updateUser,
}) => {
	const mainContext = useContext(MainContext)
	const [isOpenUpd, setIsOpenUpd] = useState<boolean>(false)

	const handleDoubleClickUser = (user: UserProfileType) => {
		if (currentProfile?.id === user.id) {
			setIsOpenUpd(true)
		}
	}

	const cancelUpdUser = () => {
		setIsOpenUpd(false)
	}

	const updateUser_ = (user: UserProfileType) => {
		updateUser(user)
		setIsOpenUpd(false)
	}

	return (
		<Box p={1}>
			<FlexBetween>
				<Button variant="contained" onClick={showAddUserForm}>
					{ADD_USER_T}
				</Button>
				<ButtonGroup variant="outlined" aria-label="loading button group">
					<Button
						onClick={() => switchMode("test")}
						sx={mode === "test" ? {} : { background: "none" }}
					>
						{TESTS_TITLE_T}
					</Button>
					<Button
						onClick={() => switchMode("chatGPT")}
						sx={mode === "chatGPT" ? {} : { background: "none" }}
					>
						{"GPT 4"}
					</Button>
				</ButtonGroup>
			</FlexBetween>

			<Box sx={{ display: "flex", alignContent: "space-between" }}>
				<Box sx={{ width: "20%" }}>
					{!!mainContext && (
						<ProfilesListDesktop
							users={mainContext?.users}
							handleSelectUser={handleSelectUser}
							currentProfile={currentProfile}
							handleDoubleClickUser={handleDoubleClickUser}
						/>
					)}
				</Box>
				<Box sx={{ width: "80%" }}>
					{mode === "test" && (
						<ProfileTestBlockDesktop currentProfile={currentProfile} />
					)}
					{mode === "chatGPT" && (
						<ProfileChatGPTBlockDesktop currentProfile={currentProfile} />
					)}
				</Box>
			</Box>
			{!!isOpen && <ModalAddForm open={isOpen} closeModal={closeModal} />}
			{!!isOpenUpd && currentProfile && (
				<ModalUpdateFormUser
					open={isOpenUpd}
					closeModal={cancelUpdUser}
					currentProfile={currentProfile}
					updateUser={updateUser_}
				/>
			)}
		</Box>
	)
}

export default ProfilesWrapperDesktop
