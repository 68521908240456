import React, { FC, useContext, useEffect, useState } from "react"
import {
	StatisticType,
	UserProfileType,
	statisticResultCommonStateType,
} from "../../../TypesComp/TypesComp"
import { Box, Stack, Typography } from "@mui/material"
import CalendarPage from "../../calendar/CalendarPage"
import {
	FlexBetween,
	FlexEvenly,
	ItemStack,
	ListBlockFlex,
} from "../../../theme/styledComponents"
import StatisticElemBlock from "../presentElems/StatisticElemBlock"
import StatisticResultElemPresent from "../presentElems/StatisticResultElemPresent"
import { MainContext } from "../../../App"
import { LOCALE_DATE_PARAM_STRING_RU } from "../../../CONSTANTS/constsSetting"
import { getStatisticFromBase } from "../../../API/RequestServices"

interface ProfileTestBlockDesktopProps {
	currentProfile: UserProfileType | undefined
	// statistiks: StatisticType[]
}
const ProfileTestBlockDesktop: FC<ProfileTestBlockDesktopProps> = ({
	currentProfile,
	// statistiks,
}) => {
	const mainContext = useContext(MainContext)
	const [statisticResultCommonState, setStatisticResultCommonState] = useState<
		statisticResultCommonStateType | undefined
	>()

	const [filteredStatistic, setFilteredStatistic] = useState<StatisticType[]>(
		[]
	)

    	// const [statistiks, setStatistiks] = useState<StatisticType[]>([])

        useEffect(() => {
            if (!!currentProfile) {
                const _getStatisticFromBase = async () => {
                    await getStatisticFromBase(currentProfile.id).then(
                        (response: StatisticType[] | string) => {
                            if (typeof response !== "string") {
                                mainContext?.setStatistic([
                                    ...response.map((foo) => {
                                        return {
                                            ...foo,
                                            date: !!foo.date ? new Date(foo.date) : undefined,
                                        }
                                    }),
                                ])
                            }
                        }
                    )
                }
                _getStatisticFromBase()
            }
        }, [currentProfile])

	useEffect(() => {
		if (mainContext?.selectedDate && !!mainContext?.statistics.length) {
			setFilteredStatistic([
				...mainContext?.statistics.filter(
					(foo) =>
						foo.date?.toLocaleDateString([LOCALE_DATE_PARAM_STRING_RU]) ===
						mainContext.selectedDate?.toLocaleDateString([
							LOCALE_DATE_PARAM_STRING_RU,
						])
				),
			])
		} else {
			setFilteredStatistic([])
		}
	}, [mainContext?.selectedDate, mainContext?.statistics])
	// const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

	// useEffect(() => {
	// 	console.log(statisticResultCommonState)
	// }, [statisticResultCommonState])

	useEffect(() => {
		if (!!mainContext?.statistics.length) {
			const getTrueOrFalse = (description: string | undefined) => {
				if (!!description) {
					const arrFromDesc = description.split(",")[0].split("/")
					const res = 100 - (+arrFromDesc[0] / +arrFromDesc[1]) * 100
					return !(res > 14.3)
					// return !true
				} else {
					return false
				}
			}
			const getCountQuestionsArr = (description: string | undefined) => {
				if (!!description) {
					const arrFromDesc = description.split(",")[0].split("/")
					return +arrFromDesc[1]
				} else {
					return 0
				}
			}

			let countSubjects = new Set(mainContext?.statistics.map((foo) => foo.subject_name))
				.size
			let countChapters = new Set(mainContext?.statistics.map((foo) => foo.chapter_name))
				.size
			let resultsTests = mainContext?.statistics.map((foo) =>
				getTrueOrFalse(foo.description)
			)
			let countTrueTests = resultsTests.filter((foo) => foo === true).length
			let countFailTests = resultsTests.filter((foo) => foo === false).length
			let countQuestionsArr = mainContext?.statistics.map((foo) =>
				getCountQuestionsArr(foo.description)
			)
			let countQuestions = countQuestionsArr.reduce(
				(acc, number) => acc + number,
				0
			)
			setStatisticResultCommonState({
				countSubjects,
				countChapters,
				countTrueTests,
				countFailTests,
				countQuestions,
			})
			// console.log(statistiks)
		} else {
			setStatisticResultCommonState(undefined)
		}
	}, [mainContext?.statistics])

	return (
		<Box sx={{ width: "100%" }}>
			{/* <FlexEvenly>
				<Typography>
					{"Информация о пройденых на выбранную дату тестах по: "}
				</Typography>

				<Typography variant="h6" color={"text.success"}>
					{currentProfile?.name}
				</Typography>
			</FlexEvenly> */}

			<FlexBetween sx={{ alignItems: "start", width: "100%" }}>
				<ListBlockFlex sx={{ width: "50%", height: window.innerHeight - 120 }}>
					<Box sx={{ maxWidth: 400 }}>
						<CalendarPage />
					</Box>

					{!!statisticResultCommonState && (
						<StatisticResultElemPresent
							statisticResult={statisticResultCommonState}
						/>
					)}
				</ListBlockFlex>
				<Box sx={{ width: "50%" }}>
					<StatisticElemBlock
						filteredStatistic={filteredStatistic}
					/>
					{/* {filteredStatistic.map((st) => {
						return <StatisticElemBlock key={st?.id} st={st} />
					})} */}
				</Box>
			</FlexBetween>
		</Box>
	)
}

export default ProfileTestBlockDesktop
