import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import s from "../CalendarNew.module.css";

interface SelectedMonthProps {
    selectedMonth:number
    setSelectedMonth:Dispatch<SetStateAction<number>>
    languageDesignation:string
}
const SelectedMonth:React.FC<SelectedMonthProps> = ({selectedMonth, setSelectedMonth, languageDesignation}) => {

    const [monthList, setMonthList] = useState<Array<string>>()

    useEffect(() => {
        let month_list: Array<string> = []
        for (let i = 0; i < 12; i++) {
            month_list.push(new Date(2022, i,1).toLocaleString(languageDesignation, {month: 'short'}))
        }
        setMonthList(
            [...month_list]
        )
    }, [languageDesignation])
    return (
        <div className={s.monthBlockForSelect}>
            {monthList?.map((elem, index) => {
                return (
                    <div key={index} onClick={()=>setSelectedMonth(index)}>
                        <div className={index===selectedMonth? s.monthElem+' '+s.monthElemSelected: s.monthElem}>
                            {elem}
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default SelectedMonth;