import { Box, Divider, IconButton, Typography } from "@mui/material"
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react"
import { CHAPTERS_T } from "../../../CONSTANTS/constsTrans"
// import FlexBetween from "../../../components/flex-between"
import AddIcon from "@mui/icons-material/Add"
import {
	addChapterToBase,
	putChapterToBase,
} from "../../../API/RequestServices"
import { ChapterType, SubjectType } from "../../../TypesComp/TypesComp"
import ChapterPresentElem from "./presentElems/ChapterPresentElem"
import ChapterForm from "./forms/ChapterForm"
import { ListBlock } from "../../../theme/styledComponents"
import { sortChaptersById } from "../../../BLL/sorts"
import FlexEvenly from "../../../components/flex-evenly"
import { MainContext } from "../../../App"

interface ChapterPageProps {
	curentSubject: SubjectType | undefined
	currentChapter: ChapterType | undefined
	setCurrentChapter: Dispatch<SetStateAction<ChapterType | undefined>>
	chapters: ChapterType[]
	setChapters: Dispatch<SetStateAction<ChapterType[]>>
	selectChapter(chapter: ChapterType): void
}
const ChapterPage: FC<ChapterPageProps> = ({
	curentSubject,
	currentChapter,
	setCurrentChapter,
	setChapters,
	chapters,
	selectChapter,
}) => {
	const mainContext = useContext(MainContext)
	const [isOpenFormChapter, setIsOpenFormChapter] = useState<boolean>(false)

	useEffect(() => {
		console.log("chapters: ", chapters)
	}, [chapters])

	const addChapter = (nChapter: ChapterType) => {
		const addChapToBase = async () => {
			const data = {
				name: nChapter.name,
				subject: curentSubject?.id.toString(),
				is_active: nChapter.is_active.toString(),
			}
			// nChapter.id !== "string" - id может быть строкой, если передается в параметре для получения вопросов по всем
			if (!!nChapter.id) {
				await putChapterToBase(nChapter.id, data).then(
					(chap: ChapterType | string) => {
						if (typeof chap === "string") {
							// TODO обработка ошибки
						} else {
							setChapters((prev) => [
								...prev.filter((elem) => elem.id !== chap.id),
								chap,
							])
							setCurrentChapter(chap)
						}
						setIsOpenFormChapter(false)
					}
				)
			} else {
				await addChapterToBase(data).then((chap: ChapterType | string) => {
					if (typeof chap === "string") {
						// TODO обработка ошибки
					} else {
						setChapters((prev) => [...prev, chap])
					}
					setIsOpenFormChapter(false)
				})
			}
		}

		!!curentSubject && addChapToBase()
	}
	const openFormChapter = () => {
		mainContext?.currentUser?.is_staff && setIsOpenFormChapter(true)
	}

	const openFormChapterForAdd = () => {
		if (!!curentSubject && mainContext?.currentUser?.is_staff) {
			setCurrentChapter(undefined)
			setIsOpenFormChapter(true)
		}
	}

	// const selectChapter = (chapter: ChapterType) => {
	// 	setCurrentChapter(chapter)

	// }
	return (
		<Box>
			<FlexEvenly>
				<Typography>{CHAPTERS_T}</Typography>
				{mainContext?.currentUser?.is_staff &&
					<IconButton
						aria-label="add"
						color="success"
						onClick={openFormChapterForAdd}
					>
						<AddIcon />
					</IconButton>
				}
			</FlexEvenly>
			<ListBlock
				direction={"column"}
				divider={<Divider orientation="vertical" flexItem />}
				spacing={1}
			>
				{sortChaptersById(chapters).map((chap) => {
					return (
						<ChapterPresentElem
							key={chap.id}
							chapter={chap}
							selectChapter={selectChapter}
							ondblclick={openFormChapter}
							is_current={chap === currentChapter}
						/>
					)
				})}
			</ListBlock>
			{isOpenFormChapter && (
				<ChapterForm
					chapter={currentChapter}
					open={isOpenFormChapter}
					addChapter={addChapter}
					setOpen={setIsOpenFormChapter}
				/>
			)}
		</Box>
	)
}

export default ChapterPage
