import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { AnswerType } from "../../../../TypesComp/TypesComp"
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControlLabel,
	Modal,
} from "@mui/material"
import TextInput from "../../../../components/inputs/TextInput"
import {
	ANSWER_NAME_T,
	CANCEL_T,
	SAVE_T,
	SUBMIT_T,
} from "../../../../CONSTANTS/constsTrans"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import { pink } from "@mui/material/colors"

interface AnswerFormProps {
	answer: AnswerType | undefined
	open: boolean
	addAnswer(a: AnswerType): void
	setOpen: Dispatch<SetStateAction<boolean>>
}
const AnswerForm: FC<AnswerFormProps> = ({
	answer,
	open,
	addAnswer,
	setOpen,
}) => {
	const [nAnswer, setNAnswer] = useState<AnswerType>({
		name: "",
		question: NaN,
		is_true: false,
		is_active: true,
	})
	const isLoading = false


	useEffect(() => {
		if (!!answer) {
			setNAnswer(answer)
		}
		// else {
		// 	setNAnswer({
		// 		name: "",
		// 		question: NaN,
		// 		is_true: false,
		// 		is_active: false,
		// 		is_checked: false,
		// 	})
		// }
	}, [answer])

	const setStateAnswerValue = (val: Partial<AnswerType>) => {
		nAnswer && setNAnswer({ ...nAnswer, ...val })
	}

	const validateName = (name: string) => {
		setStateAnswerValue({ name })
	}

	const validateActive = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStateAnswerValue({ is_active: event.target.checked })
	}
	// const validateChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setStateAnswerValue({ is_checked: event.target.checked })
	// }
	const validateIsTrue = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStateAnswerValue({ is_true: event.target.checked })
	}

	const cancel = () => {
		setOpen(false)
	}



	const onSubmit = () => {
		addAnswer({
			id: nAnswer?.id,
			name: !!nAnswer ? nAnswer.name : "",
			is_active: !!nAnswer ? nAnswer.is_active : true,
			question: !!nAnswer ? nAnswer.question : NaN,
			is_true: !!nAnswer ? nAnswer.is_true : false,
		})
	}
	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="chapter-modal-form"
			aria-describedby="chapter-modal-description"
		>
			<Box sx={style}>
				<TextInput
					label={ANSWER_NAME_T}
					value={nAnswer.name}
					onChange={validateName}
					props={{ multiline: true }}
				/>
				<Box>
					<FormControlLabel
						label="is active"
						control={
							<Checkbox
								checked={nAnswer.is_active}
								onChange={validateActive}
								inputProps={{ "aria-label": "is_active" }}
								sx={{
									color: pink[800],
									"&.Mui-checked": {
										color: pink[600],
									},
								}}
							/>
						}
					/>

					<FormControlLabel
						label="is true"
						control={
							<Checkbox
								// color="success"
								checked={nAnswer.is_true}
								onChange={validateIsTrue}
								inputProps={{ "aria-label": "is_true" }}
								sx={{
									color: pink[800],
									"&.Mui-checked": {
										color: pink[600],
									},
								}}
							/>
						}
					/>
				</Box>

				<ButtonGroup variant="outlined" aria-label="loading button group">
					<Button onClick={cancel}>{CANCEL_T}</Button>

					<LoadingButton
						loading={isLoading}
						loadingPosition="start"
						startIcon={<SaveIcon />}
						onClick={onSubmit}
					>
						{isLoading ? SAVE_T : SUBMIT_T}
					</LoadingButton>
				</ButtonGroup>
			</Box>
		</Modal>
	)
}

const style = {
	position: "absolute" as "absolute",
	display: "flex",
	flexDirection: "column",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}
export default AnswerForm
