import React, { FC } from "react"
import { UserProfileType } from "../../../TypesComp/TypesComp"
import { ListBlock } from "../../../theme/styledComponents"
import ProfilePresent from "../presentElems/ProfilePresent"
import { Divider } from "@mui/material"

interface ProfilesListDesktopProps {
	users: UserProfileType[]
	handleSelectUser(user: UserProfileType): void
	currentProfile: UserProfileType | undefined
	handleDoubleClickUser(user: UserProfileType): void
}
const ProfilesListDesktop: FC<ProfilesListDesktopProps> = ({
	users,
	handleSelectUser,
	currentProfile,
	handleDoubleClickUser,
}) => {
	return (
		<ListBlock
			mt={2}
			direction={"column"}
			divider={<Divider orientation="vertical" flexItem />}
			spacing={1}
		>
			{users.map((user) => (
				<ProfilePresent
					key={user.id}
					user={user}
					handleSelectUser={handleSelectUser}
					handleDoubleClick={handleDoubleClickUser}
					selected={currentProfile?.id === user.id}
				/>
			))}
		</ListBlock>
	)
}

export default ProfilesListDesktop
