import React, { FC, useState, useEffect } from "react"
import { AnswerType } from "../../../../TypesComp/TypesComp"
import { useTheme } from "@mui/material"
import { ItemStack } from "../../../../theme/styledComponents"

interface AnswerPresentElemProps {
	isTest?: boolean
	answer: AnswerType
	ondblclick(): void
	selectAnswer(a: AnswerType): void
	is_current: boolean
}
const AnswerPresentElem: FC<AnswerPresentElemProps> = ({
	answer,
	ondblclick,
	selectAnswer,
	is_current,
	isTest = false
}) => {
	const theme = useTheme()
	const [selColor, setSelColor] = useState(theme.palette.primary.main)
	useEffect(() => {
		if (is_current) {
			setSelColor("green")
		} else if (!isTest && answer.is_true) {
			setSelColor("darkorange")
		} else {
			setSelColor(theme.palette.primary.main)
		}

	}, [is_current, theme.palette.primary.main])

	const handleDoubleClick = () => {
		ondblclick()
	}
	return (
		<ItemStack
			onClick={() => selectAnswer(answer)}
			sx={{ border: `2px solid ${selColor}` }}
			onDoubleClick={handleDoubleClick}
		>
			{answer.name}
		</ItemStack>
	)
}

export default AnswerPresentElem
