import {
	Box,
	Button,
	Fade,
	IconButton,
	Menu,
	MenuItem,
	useMediaQuery,
} from "@mui/material"
import React, { Dispatch, FC, SetStateAction } from "react"
import { SubjectType } from "../../TypesComp/TypesComp"
import { SELECT_SUBJECT_T } from "../../CONSTANTS/constsTrans"
import AddIcon from "@mui/icons-material/Add"
import { FlexBetween } from "../../theme/styledComponents"

interface SubjectsPageProps {
	subjects: SubjectType[]
	getSubjects(): void
	currentSubject: SubjectType | undefined
	// setCurrentSubject: Dispatch<SetStateAction<SubjectType | undefined>>
	selectSubject(sub: SubjectType | undefined): void
}

const SubjectsPage: FC<SubjectsPageProps> = ({
	subjects,
	currentSubject,
	// setCurrentSubject,
	getSubjects,
	selectSubject,
}) => {
	const isNonMobile = useMediaQuery("(min-width:600px)")
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		getSubjects()
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (sub: SubjectType) => {
		selectSubject(sub)
		setAnchorEl(null)
	}

	const addSubject = () => {
		console.log("addSubject")
	}
	return (
		<FlexBetween>
			<Box pr={2} m={1}>
				<Button
					id="fade-button"
					aria-controls={open ? "fade-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					color="secondary"
					variant="contained"
					// sx={{ color: "white" }}
				>
					{currentSubject?.name ? currentSubject.name : SELECT_SUBJECT_T}
				</Button>

				<Menu
					id="fade-menu"
					MenuListProps={{
						"aria-labelledby": "fade-button",
					}}
					container={() => document.getElementsByClassName("fullscreen")[0]}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					{subjects.map((sub) => {
						return (
							<MenuItem key={sub.id} onClick={() => handleClose(sub)}>
								{sub.name}
							</MenuItem>
						)
					})}
				</Menu>
			</Box>
			{false && isNonMobile && (
				<IconButton aria-label="add" color="success" onClick={addSubject}>
					<AddIcon />
				</IconButton>
			)}
		</FlexBetween>
	)
}

export default SubjectsPage
