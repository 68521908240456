import React, { Dispatch, SetStateAction } from "react"
import s from "../CalendarNew.module.css"
import SelectedYear from "./SelectedYear"
import SelectedMonth from "./SelectedMonth"
import { useMediaQuery } from "@mui/material"
import { NON_MOBILE } from "../../../../CONSTANTS/constsSetting"

interface SelectedPeriodModProps {
	languageDesignation: string
	selectedYear: number
	setSelectedYear: Dispatch<SetStateAction<number>>
	selectedMonth: number
	setSelectedMonth: Dispatch<SetStateAction<number>>
}

const SelectedPeriodMod: React.FC<SelectedPeriodModProps> = ({
	setSelectedYear,
	selectedYear,
	setSelectedMonth,
	selectedMonth,
	languageDesignation,
}) => {
	const isNonMobile = useMediaQuery(NON_MOBILE)
	return (
		<div className={s.selectedPeriodBlockWrapper}>
			<div className={s.selectedPeriodBlockInner}>
				<div
					className={s.selectedBlocksWrapper}
					// style={{ height: "100%" }}
					style={{ height: window.innerHeight - 110 }}
				>
					<SelectedYear
						selectedYear={selectedYear}
						setSelectedYear={setSelectedYear}
					/>
					<SelectedMonth
						selectedMonth={selectedMonth}
						setSelectedMonth={setSelectedMonth}
						languageDesignation={languageDesignation}
					/>
				</div>
			</div>
		</div>
	)
}

export default SelectedPeriodMod
