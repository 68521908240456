import { Box, useMediaQuery } from "@mui/material"
import React, { FC, useContext } from "react"
import SwitchBlock from "./comp/SwitchBlock"
import {
	AnswerType,
	ChapterType,
	QuestionsType,
	SubjectType,
} from "../../TypesComp/TypesComp"
// import { User } from "../../App"
import TestWolk from "./TestWolk"
import TestInter from "./TestInter"
import { useState, useEffect } from "react"
import {
	addQuestionsToBase,
	getAnswersFromBase,
	getChaptersFromBase,
	getQuestionsFromBase,
	getSubjectsFromBase,
	putQuestionsToBase,
} from "../../API/RequestServices"
import SubjectsPage from "./SubjectsPage"
import ChapterButtonMenu from "./ChapterButtonMenu"
import { FlexBetween } from "../../theme/styledComponents"
import { MainContext } from "../../App"

const TestsWrapper: FC = () => {
	// const [headerHeight, setHeaderHeight] = useState<number>(0)
	const mainContext = useContext(MainContext)
	const isNonMobile = useMediaQuery("(min-width:600px)")
	const [testMode, setTestMode] = React.useState("walkthrought")
	const [Subjects, setSubjects] = React.useState<SubjectType[]>([])
	const [chapters, setChapters] = useState<ChapterType[]>([])
	const [CurrentSubject, setCurrentSubject] = React.useState<
		SubjectType | undefined
	>(undefined)
	const [currentChapter, setCurrentChapter] = useState<ChapterType | undefined>(
		undefined
	)
	const [mod, setMod] = React.useState<"preStart" | "start" | "finish">(
		"preStart"
	)
	const [questions, setQuestions] = React.useState<QuestionsType[]>([])
	const [answers, setAnswers] = React.useState<AnswerType[]>([])

	const [currentQuestion, setCurrentQuestion] = useState<
		QuestionsType | undefined
	>(undefined)
	const [currentAnswer, setCurrentAnswer] = useState<AnswerType | undefined>(
		undefined
	)

	// const refHEx = createRef()
	// useEffect(() => {
	// 	if (!!refHEx.current) {
	// 		//@ts-ignore
	// 		const heightHEx = refHEx?.current?.getBoundingClientRect().height
	// 		setHeaderHeight(heightHEx)
	// 	}
	// }, [refHEx])

	const getSubjects = async () => {
		await getSubjectsFromBase().then((response: SubjectType[]) => {
			setSubjects(response)
			setQuestions([])
			setAnswers([])
		})
	}

	const getQuestions = (chapter: ChapterType) => {
		const getQFromBase = async () => {
			!!chapter.id &&
				CurrentSubject?.id &&
				(await getQuestionsFromBase(chapter.id, CurrentSubject.id).then(
					(ques: QuestionsType[]) => {
						if (typeof ques === "string") {
							// TODO обработка ошибки
						} else {
							// setCurrentQuestion(undefined)
							setQuestions(ques)
						}
					}
				))
		}
		getQFromBase()
	}

	// useEffect(() => {
	// 	if (!!CurrentSubject) {
	// 		const getChapFromBase = async () => {
	// 			getChaptersFromBase(CurrentSubject.id).then(
	// 				(chapters_: ChapterType[] | string) => {
	// 					if (typeof chapters_ !== "string") {
	// 						setChapters(
	// 							// chapters.filter((elem) => elem.subject == CurrentSubject.id)
	// 							chapters_
	// 						)
	// 					}

	// 					setCurrentChapter(undefined)
	// 				}
	// 			)
	// 		}
	// 		getChapFromBase()
	// 	} else {
	// 		setChapters([])
	// 	}
	// }, [CurrentSubject]) // getChaptersFromBase

	const addQuation = (nQuation: QuestionsType) => {
		const addQuatToBase = async () => {
			const formData = new FormData()
			!!nQuation.file &&
				formData.append("image", nQuation.file, nQuation.file.name)
			formData.append("name", nQuation.name)
			formData.append("chapter", currentChapter!.id!.toString())
			formData.append("is_active", nQuation.is_active.toString())
			formData.append("is_check", nQuation.is_check.toString())

			if (!!nQuation.id) {
				await putQuestionsToBase(nQuation.id, formData).then(
					(quat: QuestionsType) => {
						if (typeof quat === "string") {
							// TODO обработка ошибки
						} else {
							setQuestions((prev) => [
								...prev.filter((elem) => elem.id !== quat.id),
								quat,
							])
							selectQuestion(quat)
							// setIsOpenForm(false)
						}
					}
				)
			} else {
				await addQuestionsToBase(formData).then((quat: QuestionsType) => {
					if (typeof quat === "string") {
						// TODO обработка ошибки
					} else {
						setQuestions((prev) => [...prev, quat])
						// setIsOpenForm(false)
						selectQuestion(quat)
					}
				})
			}
		}

		!!currentChapter && addQuatToBase()
	}

	const selectSubject = (sub: SubjectType) => {
		setCurrentSubject(sub)
		const getChapFromBase = async () => {
			getChaptersFromBase(sub.id).then((chapters_: ChapterType[] | string) => {
				if (typeof chapters_ !== "string") {
					setChapters(
						// chapters.filter((elem) => elem.subject == CurrentSubject.id)
						chapters_
					)
				}
			})
		}
		getChapFromBase()
		setCurrentChapter(undefined)
		setQuestions([])
		setAnswers([])
	}

	const selectQuestion = (q: QuestionsType | undefined) => {
		setCurrentQuestion(q)
		if (!!q?.id) {
			const getAFromBase = async () => {
				!!q.id &&
					(await getAnswersFromBase(q.id).then((answ: AnswerType[]) => {
						if (typeof answ === "string") {
							// TODO обработка ошибки
						} else {
							setAnswers(answ)
						}
					}))
			}
			getAFromBase()
		} else {
			setAnswers([])
			setCurrentAnswer(undefined)
		}
	}

	const selectAnswer = (answ: AnswerType) => {
		setCurrentAnswer(answ)
	}

	return (
		<Box p={1} sx={{ width: "100%", height: "100%" }}>
			{mod === "preStart" && (
				<FlexBetween>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<SubjectsPage
							subjects={Subjects}
							getSubjects={getSubjects}
							currentSubject={CurrentSubject}
							selectSubject={selectSubject}
						/>
						{!!chapters.length && (
							<ChapterButtonMenu
								chapters={chapters}
								setCurrentChapter={setCurrentChapter}
								currentChapter={currentChapter}
							/>
						)}
					</Box>

					{isNonMobile && (
						<SwitchBlock testMode={testMode} setTestMode={setTestMode} />
					)}
					{/* {mainContext?.currentUser?.is_staff && isNonMobile && (
						<SwitchBlock testMode={testMode} setTestMode={setTestMode} />
					)} */}
				</FlexBetween>
			)}

			{testMode === "walkthrought" && !!CurrentSubject && (
				<TestWolk
					currentChapter={currentChapter}
					setMod={setMod}
					mod={mod}
					currentSubject={CurrentSubject}
				/>
			)}
			{testMode === "inter" && (
				<TestInter
					currentSubject={CurrentSubject}
                    currentChapter={currentChapter}
                    currentQuestion={currentQuestion}
                    currentAnswer={currentAnswer}
                    //
					chapters={chapters}
                    questions={questions}
                    answers={answers}
                    //
					setChapters={setChapters}
					setQuestions={setQuestions}
                    setAnswers={setAnswers}
                    //
					setCurrentChapter={setCurrentChapter}
                    selectQuestion={selectQuestion}
					selectAnswer={selectAnswer}
					// 
					getQuestions={getQuestions}
					addQuation={addQuation}
				/>
			)}
		</Box>
	)
}

export default TestsWrapper
