import { useMediaQuery } from "@mui/material"
import React, { FC, useContext, useState } from "react"
import { MainContext } from "../../App"

import { UserAddFormType, UserProfileType } from "../../TypesComp/TypesComp"
import {
	createAcc,
	createAccPutOwner,
	putUserProfileToBase,
} from "../../API/RequestServices"
import { NON_MOBILE } from "../../CONSTANTS/constsSetting"

import ProfilesWrapperDesktop from "./desktop/ProfilesWrapperDesktop"
import ProfilesWrapperMobile from "./mobil/ProfilesWrapperMobile"

interface ProfilesWrapperProps {}
const ProfilesWrapper: FC = () => {
	const mainContext = useContext(MainContext)
	const isNonMobile = useMediaQuery(NON_MOBILE)
	const [isOpen, setOpen] = useState<boolean>(false)
	const [mode, setMode] = useState<"test" | "chatGPT">("test")
	const [currentProfile, setCurrentProfile] = useState<
		UserProfileType | undefined
	>()

	const showAddUserForm = () => {
		setOpen(true)
	}

	const callPostCheckMe = async (newUser: any) => {
		await createAccPutOwner({ uid: newUser.id }).then(
			(response: UserProfileType | string) => {
				if (typeof response !== "string") {
					mainContext?.setUsers((prev) => [...prev, response])
				} else {
					console.log(response)
				}
			}
		)
		// console.log(newUser)
	}
	const onSubmitCreate = async (ob: UserAddFormType) => {
		await createAcc({
			username: ob.name,
			password: ob.pass,
		}).then((response) => {
			if (response.status === 201) {
				callPostCheckMe(response.data)
			} else {
				console.log("status => ", response.status)
			}
		})
	}

	const closeModal = (ob: UserAddFormType | undefined) => {
		if (ob) {
			onSubmitCreate(ob)
		}
		setOpen(false)
	}
	const switchMode = (m: "test" | "chatGPT") => {
		setMode(m)
	}
	const handleSelectUser = (user: UserProfileType) => {
		setCurrentProfile(user)
	}
	const updateUser = (user: UserProfileType) => {
		const _putUserProfileToBase = async () => {
			await putUserProfileToBase(user.id, user).then(
				(response: UserProfileType | string) => {
					if (typeof response !== "string") {
						mainContext?.setUsers((prev) => [
							...prev.filter((us) => us.id !== response.id),
							response,
						])
						setCurrentProfile(response)
					} else {
						console.error("status => ", response)
					}
				}
			)
		}

		_putUserProfileToBase()
	}

	return (
		<>
			{isNonMobile ? (
				<ProfilesWrapperDesktop
					showAddUserForm={showAddUserForm}
					switchMode={switchMode}
					mode={mode}
					handleSelectUser={handleSelectUser}
					updateUser={updateUser}
					currentProfile={currentProfile}
					isOpen={isOpen}
					closeModal={closeModal}
				/>
			) : (
				<ProfilesWrapperMobile
					showAddUserForm={showAddUserForm}
					switchMode={switchMode}
					mode={mode}
					handleSelectUser={handleSelectUser}
					currentProfile={currentProfile}
					isOpen={isOpen}
					closeModal={closeModal}
				/>
			)}
		</>
	)
}

export default ProfilesWrapper
