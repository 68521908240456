import React, { FC } from "react"
import { statisticChatGPTResultType } from "../../../TypesComp/TypesComp"
import { Box, Stack, Typography } from "@mui/material"
import { ItemStack } from "../../../theme/styledComponents"
import {
	COUNT_AVERAGE_MSG_LABEL_T,
	COUNT_MSG_LABEL_T,
} from "../../../CONSTANTS/constsTrans"

interface StatisticChatResultElemPresentProps {
	statisticResult: statisticChatGPTResultType | undefined
}
const StatisticChatResultElemPresent: FC<
	StatisticChatResultElemPresentProps
> = ({ statisticResult }) => {
	return (
		<Stack spacing={0.3}>
			<ItemStack>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_MSG_LABEL_T}</Typography>
					<Typography ml={2}>{statisticResult?.countMsg}</Typography>
				</Box>
				<Box sx={{ display: "inline-flex" }}>
					<Typography>{COUNT_AVERAGE_MSG_LABEL_T}</Typography>
					<Typography ml={2}>
						{statisticResult?.average_number_messages_per_day}
					</Typography>
				</Box>
			</ItemStack>
		</Stack>
	)
}

export default StatisticChatResultElemPresent
