import {
	Box,
	Button,
	IconButton,
	AppBar,
	styled,
	CssBaseline,
	Stack,
	Typography,
	useTheme,
} from "@mui/material"
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import {
	AnswerType,
	ChapterType,
	QuestionsType,
	StatisticType,
	StructureUseAnswer,
	SubjectType,
} from "../../TypesComp/TypesComp"
import {
	FINISH_TITLE_T,
	START_TITLE_T,
	START_AGAIN_TITLE_T,
	CONTINUM_TITLE_T,
	TEST_FAILED_T,
	TEST_PASSED_T,
	SELECT_FROM_ALL_T,
	TITLE_MAIN_TEST_T,
	TITLE_BTN_CLEAR_TEST_LIST_T,
} from "../../CONSTANTS/constsTrans"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {
	addStatisticsToBase,
	getAnswersFromBase,
	getQuestionsFromBase,
	get_post_random,
} from "../../API/RequestServices"
import QuestionAndAnswerscontent from "./TestWolkComp/QuestionAndAnswerscontent"
import ConfirmForm from "./TestInterComp/forms/ConfirmForm"

const FlexBtnBlock = styled(Box)({
	width: "95%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	flexWrap: "wrap",
})
interface TestWolkProps {
	currentChapter: ChapterType | undefined
	mod: "preStart" | "start" | "finish"
	setMod: Dispatch<SetStateAction<"preStart" | "start" | "finish">>
	currentSubject: SubjectType | undefined
}
const TestWolk: FC<TestWolkProps> = ({
	currentChapter,
	mod,
	setMod,
	currentSubject,
}) => {
	const theme = useTheme()

	const [questions, setQuestions] = React.useState<QuestionsType[]>([])
	const [questionsFromBase, setQuestionsFromBase] = React.useState<
		QuestionsType[]
	>([])

	const [answers, setAnswers] = React.useState<AnswerType[]>([])
	const [currentQuestion, setCurrentQuestion] = useState<
		QuestionsType | undefined
	>(undefined)
	const [curIndex, setCurIndex] = React.useState<number>(-1)

	const [structureUseAnswer, setStructureUseAnswer] =
		useState<StructureUseAnswer>()
	const [structureList, setStructureList] = useState<StructureUseAnswer[]>([])
	const [messageTest, setMessageTest] = useState<string>()
	const [currentList, setCurrentList] = useState<number[]>([]) // список пройденых
	const [startTime, setStartTime] = useState<number>()
	const [isShowConfirmForm, setIsShowConfirmForm] = useState<boolean>(false)

	useEffect(() => {
		// алгоритм тасования Фишера — Йетса:
		const tempArr: QuestionsType[] = [...questionsFromBase]
		let m = tempArr.length,
			t,
			i

		// Пока есть элементы для перемешивания
		while (m) {
			// Взять оставшийся элемент
			i = Math.floor(Math.random() * m--)
			// И поменять его местами с текущим элементом
			t = tempArr[m]
			tempArr[m] = tempArr[i]
			tempArr[i] = t
		}
		setQuestions(tempArr)
	}, [questionsFromBase])

	useEffect(() => {
		const currentList_: number[] = questions
			.filter((foo) => !currentList.includes(foo.id!))
			.map((elem) => elem.id!)
		setCurrentList((prev) => [...prev, ...currentList_])

		// answers.filter((foo) => !currentList.includes(foo.id!)).map(elem=>elem.id)
	}, [questions])

	useEffect(() => {
		if (answers.length > 0) {
			const elemList = structureList.filter(
				(elem) => elem.idQuestion === currentQuestion?.id
			)[0]
			if (!!elemList) {
				setStructureUseAnswer(elemList)
			} else {
				const trueAnswer = answers.filter((foo) => foo.is_true)[0]
				// console.log("trueAnswer: ", trueAnswer.id, " ", trueAnswer.name)
				!!currentQuestion?.id &&
					trueAnswer.id &&
					setStructureUseAnswer({
						idQuestion: currentQuestion.id,
						nameQuestion: currentQuestion.name,
						selectAnswerID: NaN,
						selectAnswerName: "",
						trueAnswerID: trueAnswer.id,
						trueAnswerName: trueAnswer.name,
						checked: false,
						isAnswerTrue: false,
					})
			}
		}
	}, [answers]) // setStructureUseAnswer

	useEffect(() => {
		if (!!currentQuestion?.id) {
			const getAnFromBase = async () => {
				!!currentQuestion.id &&
					(await getAnswersFromBase(currentQuestion.id).then(
						(answ: AnswerType[]) => {
							if (typeof answ === "string") {
								// TODO обработка ошибки
							} else {
								setAnswers(answ)
							}
						}
					))
			}
			getAnFromBase()
		}
	}, [currentQuestion]) // getAnswersFromBase

	useEffect(() => {
		setCurrentQuestion(questions[0])
	}, [questions])

	useEffect(() => {
		// console.log("structureUseAnswer: ", structureUseAnswer)
		!!structureUseAnswer &&
			setStructureList((prev) => [
				...prev.filter((elem) => elem.idQuestion !== currentQuestion?.id),
				structureUseAnswer,
			])
	}, [structureUseAnswer])

	useEffect(() => {
		setCurrentQuestion(questions[curIndex])
	}, [curIndex, questions])

	const getRandom14Questions = () => {
		if (!!currentSubject?.id) {
			const dataQ = {
				current_list: currentList,
				sub: currentSubject.id,
			}
			const getQFromBase = async () => {
				await get_post_random(dataQ).then((ques: QuestionsType[]) => {
					if (typeof ques === "string") {
						// TODO обработка ошибки
					} else {
						setQuestionsFromBase(ques)
						setCurIndex(0)
					}
				})
			}
			getQFromBase()
		}
	}

	const getQuestionsFull = () => {
		const getQFromBase = async () => {
			!!currentChapter?.id &&
				currentSubject?.id &&
				(await getQuestionsFromBase(currentChapter.id, currentSubject.id).then(
					(ques: QuestionsType[]) => {
						if (typeof ques === "string") {
							// TODO обработка ошибки
						} else {
							setQuestionsFromBase(ques)
							setCurIndex(0)
						}
					}
				))
		}
		getQFromBase()
	}

	const goStart = () => {
		setStartTime(Date.now())
		if (!!currentChapter) {
			if (currentChapter.id === -1) {
				getRandom14Questions()
			} else {
				getQuestionsFull()
			}
		}
		setMod("start")
	}

	const goStartCurrentIndexListNew = () => {
		setCurrentQuestion(questions[0])
		setCurIndex(0)
		setMod("start")
	}

	const goStartNew = () => {
		setMod("preStart")
	}

	const sendDataResult = (dataResult: any) => {
		// console.log(dataResult)
		const addStatisticsToBase_ = async () => {
			await addStatisticsToBase(dataResult).then(
				(st: StatisticType | string) => {
					if (typeof st === "string") {
						// TODO обработка ошибки
					} else {
						setStartTime(undefined)
					}
				}
			)
		}

		addStatisticsToBase_()
	}

	const getResultTest = () => {
		let trueAnsw = structureList.filter((foo) => foo.isAnswerTrue).length
		let failAnsw = structureList.length - trueAnsw
		// structureList.forEach((elem) => {
		// 	if (elem.isAnswerTrue) {
		// 		trueAnsw++
		// 	} else {
		// 		failAnsw++
		// 	}
		// })

		const finishTime = Date.now()
		const procent = ((trueAnsw / (failAnsw + trueAnsw)) * 100).toFixed(2)
		const resStr =
			100 - (trueAnsw / (failAnsw + trueAnsw)) * 100 > 14.3
				? TEST_FAILED_T
				: TEST_PASSED_T

		// !!startTime&&console.log(finishTime,startTime, (finishTime - startTime).toString())
		const dataResult = {
			subject_name: currentSubject!.name,
			chapter:
				currentChapter && currentChapter?.id !== -1
					? currentChapter.id?.toString()
					: "",
			chapter_name: currentChapter ? currentChapter.name : SELECT_FROM_ALL_T,
			correct_answers: trueAnsw.toString(),
			the_best_time: !!startTime
				? Math.round((finishTime - startTime) / 1000).toString()
				: (32000).toString(),
			description: `${trueAnsw}/${structureList.length},  %: ${procent}, ${resStr}`,
		}
		sendDataResult(dataResult)

		setMessageTest(
			`Правильных ответов: ${trueAnsw}, Неверных: ${failAnsw}, Процент сдачи(%): ${procent}`
		)

		setStructureList([])
	}

	const continueFinish = () => {
		getResultTest()
		setMod("finish")
		setIsShowConfirmForm(false)
	}

	const goFinish = () => {
		// setFinishTime(new Date())
		const noAnswList = [...structureList.filter(foo=>!foo.checked)]
		if(noAnswList.length>0){
			setIsShowConfirmForm(true)
		}else{
			continueFinish()
		}

	}

	const goPrev = () => {
		setAnswers([])
		curIndex > 0 && setCurIndex((prev) => prev - 1)
	}
	const goNext = () => {
		setAnswers([])
		curIndex < questions.length - 1 && setCurIndex((prev) => prev + 1)
	}

	const checkSpell = (selectedAnswer: AnswerType) => {
		if (
			structureList.filter(
				(elem) => elem.idQuestion === currentQuestion?.id && elem.checked
			).length === 0
		) {
			structureUseAnswer &&
				setStructureUseAnswer({
					...structureUseAnswer,
					selectAnswerID: selectedAnswer.id!,
					selectAnswerName: selectedAnswer.name,
					checked: true,
					isAnswerTrue: selectedAnswer.id === structureUseAnswer.trueAnswerID,
				})
		}
	}

	const clearCurrentlist = () => {
		setCurrentList([])
	}

	const closeModal = () =>{
		setIsShowConfirmForm(false)
	}

	return (
		<Box>
			<CssBaseline />

			{mod === "preStart" && !!currentSubject && !currentChapter && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: window.innerHeight / 2,
					}}
				>
					<Typography>
						{TITLE_MAIN_TEST_T}
					</Typography>
					{currentList.length > 0 &&
						<Button variant="outlined" onClick={clearCurrentlist} color="error">
							{TITLE_BTN_CLEAR_TEST_LIST_T}
						</Button>
					}
				</Box>
			)}

			{mod === "preStart" && !!currentChapter && (
				<>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: window.innerHeight / 2,
						}}
					>
						<Typography>
							{TITLE_MAIN_TEST_T}
						</Typography>
						{currentList.length > 0 &&
							<Button variant="outlined" onClick={clearCurrentlist} color="error">
								{TITLE_BTN_CLEAR_TEST_LIST_T}
							</Button>
						}

					</Box>
					<FlexBtnBlock
						sx={{
							position: "absolute",
							bottom: 100,
						}}
					>
						<Button variant="contained" onClick={goStart} size="large">
							{START_TITLE_T}
						</Button>
					</FlexBtnBlock>
				</>
			)}

			{mod === "start" && (
				// <FullScreen handle={handleFullScreen}>
				<Box>
					<QuestionAndAnswerscontent
						currentQuestion={currentQuestion}
						answers={answers}
						structureUseAnswer={structureUseAnswer}
						checkSpell={checkSpell}
					/>

					<AppBar
						position="absolute"
						color="primary"
						sx={{
							top: "auto",
							bottom: 0,
							padding: "0!important",
							width: "100%",
							zIndex: 1099,
						}}
					>
						<FlexBtnBlock sx={{ alignItems: "center" }}>
							<IconButton onClick={goPrev} disabled={curIndex <= 0}>
								<ArrowBackIosIcon />
							</IconButton>
							<Typography color={"text.info"}>
								{structureList.length}
							</Typography>
							<Button
								onClick={goFinish}
								disabled={!structureUseAnswer?.checked}
							>
								{FINISH_TITLE_T}
							</Button>
							<Typography color={"text.info"}>
								{questions.length - structureList.length}
							</Typography>
							<IconButton
								onClick={goNext}
								disabled={curIndex >= questions.length - 1}
							>
								<ArrowForwardIosIcon />
							</IconButton>
						</FlexBtnBlock>
					</AppBar>
				</Box>

				// </FullScreen>
			)}

			{mod === "finish" && (
				<FlexBtnBlock
					sx={{
						position: "absolute",
						bottom: 100,
					}}
				>
					<Typography>{messageTest}</Typography>
					<Stack direction="row" spacing={2}>
						<Button onClick={goStartCurrentIndexListNew} size="large">
							{START_AGAIN_TITLE_T}
						</Button>
						{/* <Button onClick={goFinish} variant="contained" color='error' size='large'>{QUITE_TITLE_T}</Button> */}
						<Button
							variant="contained"
							color="success"
							onClick={goStartNew}
							size="large"
						>
							{CONTINUM_TITLE_T}
						</Button>
					</Stack>
				</FlexBtnBlock>
			)}
			{isShowConfirmForm&&
				<ConfirmForm open={isShowConfirmForm} closeModal={closeModal} onConfirm={continueFinish}/>
			}
		</Box>
	)
}

export default TestWolk
