import React, {
	FC,
	useContext,
	// useEffect, useState
} from "react"
import {
	// ButtonGroup, Button, Typography,
	Box,
	Button,
	useMediaQuery,
} from "@mui/material"
// import { ADD_USER_T } from "../../CONSTANTS/constsTrans"
// // import { LoadingButton } from "@mui/lab"
// import ModalAddForm from "./ModalAddForm"
// import { UserAddFormType, UserProfileType } from "../../TypesComp/TypesComp"
// import {
// 	createAcc,
// 	createAccPutOwner,
// 	getUsers,
// } from "../../API/RequestServices"
import { MainContext } from "../../App"
import ProfilesWrapper from "../profiles/ProfilesWrapper"
import { NON_MOBILE } from "../../CONSTANTS/constsSetting"
// import { sendTestGet } from "../../API/RequestServices"
import axios from "axios"

interface AdminToolsWrapperProps {}
const AdminToolsWrapper: FC = () => {
	const mainContext = useContext(MainContext)
	const sendTest = async () => {
		// const url = "https://www.haron.xyz/"
		// const url1 = "http://127.0.0.1:8017/"
		// const $authHost = axios.create({
		// 	baseURL: url,
		// })
		// const authInterceptor = (config: any) => {
		// 	config.headers.Authorization =
		// 		"Token 5e1788700279ee385777cecf60dd2a23fff93ce7"
		// 	// config.headers.Authorization = JSON.parse(
		// 	// 	JSON.stringify(localStorage.getItem("Authorization"))
		// 	// )
		// 	config.headers.accept = "application/json"
		// 	return config
		// }
		// $authHost.interceptors.request.use(authInterceptor)
		// $authHost.get("accounts/me/", {}).then((response) => {
		// 	console.log("request getUsers", response)
		// })
	}
	return (
		<Box>
			{/* {mainContext?.currentUser?.is_staff && (
				<Button onClick={sendTest}>Send test</Button>
			)} */}
			<ProfilesWrapper />
		</Box>
	)
}

export default AdminToolsWrapper
