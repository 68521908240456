import React, { Dispatch, FC, SetStateAction } from "react"
import { ChapterType } from "../../TypesComp/TypesComp"
import { Box, Button, Fade, Menu, MenuItem } from "@mui/material"
import {
	SELECT_CHAPTER_T,
	SELECT_FROM_ALL_T,
} from "../../CONSTANTS/constsTrans"

interface ChapterButtonMenuProps {
	currentChapter: ChapterType | undefined
	chapters: ChapterType[]
	setCurrentChapter: Dispatch<SetStateAction<ChapterType | undefined>>
}
const ChapterButtonMenu: FC<ChapterButtonMenuProps> = ({
	currentChapter,
	chapters,
	setCurrentChapter,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (ch: ChapterType) => {
		setCurrentChapter(ch)
		setAnchorEl(null)
	}

	return (
		// <FlexBetween>
		<Box>
			<Button
				id="fade-button"
				aria-controls={open ? "fade-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				color="secondary"
				variant="outlined"
			>
				{currentChapter?.name ? currentChapter.name : SELECT_CHAPTER_T}
			</Button>
			<Menu
				id="fade-menu"
				container={() => document.getElementsByClassName("fullscreen")[0]}
				MenuListProps={{
					"aria-labelledby": "fade-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{chapters.map((ch) => {
					return (
						<MenuItem key={ch.id} onClick={() => handleClose(ch)}>
							{ch.name}
						</MenuItem>
					)
				})}
				<MenuItem
					onClick={() =>
						handleClose({
							id: -1,
							name: "_all_",
							is_active: false,
							subject: 0,
						})
					}
				>
					{SELECT_FROM_ALL_T}
				</MenuItem>
			</Menu>
		</Box>

		// </FlexBetween>
	)
}

export default ChapterButtonMenu
